import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { useClassContext } from "../../Contexts/ClassContext";
import { updateMetaWithClassParams } from "../02-SetupPage/updateMetaWithClassParams";

// Create a styled version of the Switch component
const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#F2662D",
    "&:hover": {
      backgroundColor: alpha("#F2662D", theme.palette.action.hoverOpacity)
    }
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    background: "linear-gradient(to right, #F9A82C, #F2662D)", // Gradient for track fill L to R
    border: "0.5px solid black" // Add a solid black border around the track
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#404040", // Color for the non-filled portion of the Switch
    border: "2px solid black" // Add a solid black border around the rail
  }
}));

export default function ContinuousSwitch() {
  const { classId, db, continuousMode, setContinuousMode } = useClassContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? "enabled" : "disabled";
    setContinuousMode(newValue);
    updateMetaWithClassParams({ db: db, classId: classId, continuousMode: newValue });
  };

  return <CustomSwitch onChange={handleChange} checked={continuousMode === "enabled"} inputProps={{ "aria-label": "controlled" }} />;
}
