import { CSSProperties } from "react";

export const nameDisplayCardHeader: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  // paddingBottom: "0px",
  lineHeight: "0.8",
  padding: "0px",
  background: "#1E1E1E", // 1E1E1E
  display: "flex",
  alignItems: "center"
};
export const nameDisplayText: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  // fontSize: "3.2rem",
  lineHeight: "0.8",
  padding: "10px 0px 10px 5px", // top, right , bottom, left
  color: "#FFFFFF" // FFFFFF white
};

export const MUICardContentStyle: CSSProperties = {
  padding: "3px 8px 5px 8px", // top right bottom left
  display: "flex",
  flex: "1 1 100%",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "#3B3B3B"
};

export const typographyStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flex: "1 1 100%"
};
export const roundPunches: CSSProperties = {
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "0.6",
  height: "100%",
  color: "#FFFFFF"
};

export const totalPunchesAndStars: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: "600",
  color: "#FFFFFF",
  filter: "brightness(70%)",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};

export const boxingGlove: CSSProperties = {
  marginTop: "3%"
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" //trying to apply shadow, currently doesn't work as it treats the glove as a rectangle. Transparency isn't recognized
};

export const iconCountGap: CSSProperties = {
  display: "flex",
  gap: "6px",
  alignItems: "center",
  justifyContent: "center"
};

export const userCardBoxingStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  backgroundColor: "transparent",
  height: "100%" // Ensures the user card takes the full height of its parent (the grid item)
};
