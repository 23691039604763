import { ref, set, Database } from "firebase/database";

export const initializeNewClass = (db: Database, classId: string, creatorEmail: string): Promise<void> => {
  const timestamp = new Date().toISOString();
  const newClassRef = ref(db, `classes/${classId}`);

  // console.log("initializing new class with Id: ", classId, "email: ", creatorEmail);

  return set(newClassRef, {
    scores: {
      // ajg4560897asf3: { Name: "Sarah Lam", Punches: 1301, Stars: 3.3 },
      // gf997dak2kjdfu: { Name: "Janine Serene", Punches: 1204, Stars: 1.1 }
    },
    meta: {
      mode: "break",
      creator: creatorEmail,
      stateLastChanged: timestamp
    }
  });
};
