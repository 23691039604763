import { useLocation } from "react-router-dom";

import { displayTimeDecrement } from "./DisplayTimeDecrement";
// import { addMockUser } from "./addMockUser";
import { useClassContext } from "../../Contexts/ClassContext";
import { getBottomBarContent } from "./getBottomBarContent";
import { addMockUser } from "./addMockUser";

import ClassGoalProgress from "../MUIComponents/ClassGoalProgress";
import PauseModal from "../MUIComponents/PauseModal/PauseModal";

import { trapezoid, bottomBar, pauseButtonWithSubtext, timerStyle, timerText } from "./Boxing.styles";

export default function BottomBar(): JSX.Element {
  const { timer, classId, isTimerRunning, startTimer, stopTimer, setOpenModal, prevMode, db, mode, continuousMode } = useClassContext();
  const location = useLocation();
  const boxingURL = location.pathname.endsWith("/boxing");
  // const finishURL = location.pathname.endsWith("/finish");
  const continuoushURL = location.pathname.endsWith("/continuous");

  const handleAddMockUser = (): void => {
    // TODO remove this for prod, add back for dev testing
    // addMockUser(db!, classId);
  };

  const handleTimerButtonClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setOpenModal(true);
    if (boxingURL) {
      // we don't want any timing nonsense at /finish
      isTimerRunning ? stopTimer("pause") : startTimer(prevMode);
    } else if (continuoushURL) {
      if (mode === "active") {
        stopTimer("pause");
      } else if (mode === "pause") {
        startTimer(prevMode);
      } else {
        console.error("mode is not valid for continuousMode: ", continuousMode, ". Mode: ", mode);
      }
    }
  };

  return (
    <div style={bottomBar}>
      <div onClick={handleTimerButtonClick} style={pauseButtonWithSubtext}>
        {getBottomBarContent(location.pathname, isTimerRunning, mode)}
      </div>
      <PauseModal />

      <div style={trapezoid}>
        <ClassGoalProgress />
        <div style={timerStyle}>
          {!continuoushURL && <h1 style={timerText}>{displayTimeDecrement(timer)}</h1>}
          {continuoushURL && (
            <div onClick={handleAddMockUser}>
              <h1>{classId}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
