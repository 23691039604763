import { ref, set, Database } from "firebase/database";

const clearClassScores = async (db: Database, classId: string): Promise<void> => {
  try {
    const scoresRef = ref(db, `classes/${classId}/scores`);
    await set(scoresRef, null); // This sets scores to null, effectively clearing it
    console.log(`Scores cleared for class ${classId}`);
  } catch (error) {
    console.error("Error checking class timestamp or updating scores:", error);
    throw error; // Optional: Re-throw if you want calling code to handle it
  }
};

export default clearClassScores;
