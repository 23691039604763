import { CSSProperties } from "react";

export const setupContainer: CSSProperties = {
  // padding: "25px 96px 0px 0px", // top, right , bottom, left
  padding: "25px 5% 0px 0px", // top, right , bottom, left
  display: "flex",
  flexFlow: "column",
  height: "100%",
  flexGrow: 1
};

export const logoSliderClassInfo: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end"
};

export const powaLogoContainer: CSSProperties = {
  width: "10vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center"
};
export const powaLogo: CSSProperties = {
  maxHeight: "10vh"
  // paddingRight: "1vw"
  // paddingLeft: "50px"
};

export const volumeDiv: CSSProperties = {
  display: "flex",
  // alignItems: "center",
  // justifyContent: "flex-start",
  width: "43px"
};

export const volumeIcon: CSSProperties = {
  height: "40px"
};

export const sliderContainerStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",

  padding: "20px",
  backgroundColor: "rgba(217, 217, 217, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "53.437vw", // 1026/1920
  height: "47.5923vh", // 514/1080
  minHeight: "420px"
};

export const classIdPunchGoalStart: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "white",
  justifyContent: "space-between",

  backgroundColor: "rgba(217, 217, 217, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "29.895vw", // 574/1920
  height: "47.5923vh", // 514/1080
  // width: "100%", // 574/1920
  // height: "100%", // 514/1080
  minHeight: "420px",
  padding: "40px 40px 24px 40px",
  marginLeft: "20px"
};

export const classIdContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
  justifyContent: "space-between",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontSize: "64px"
};

export const classIdLabel: CSSProperties = {
  marginBlock: "0",
  fontWeight: "700"
};

export const classIdDisplay: CSSProperties = {
  marginBlock: "0",
  fontWeight: "normal",
  paddingLeft: "10px"
};

export const punchGoalContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  color: "white",
  width: "100%"
};

export const punchGoalLabel: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontSize: "40px",
  fontWeight: "700"
};

export const punchGoalInput: CSSProperties = {
  // width: "100%",
  height: "60px",
  background: "rgba(0, 0, 0, 0.32)",
  border: "none",
  borderRadius: "4px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  fontSize: "40px",
  color: "white",
  marginTop: "10px",
  padding: "0px 10px"
};

export const buttonStyle: CSSProperties = {
  background: "#f2662D",
  width: "100%",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 700
};

// user grid styles:
export const outerContainerStyle: CSSProperties = {
  // margin: "20px 96px 30px 96px", // top, right , bottom, left
  margin: "20px 0% 30px 5.3%",
  // margin: "20px 0px 0px 96px", // top, right , bottom, left // trying to make right hand side align no matter the screen size
  padding: "10px 0px 30px 60px",
  backgroundColor: "rgba(217, 217, 217, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  minHeight: "400px",
  // minWidth: "900px",
  maxWidth: "100%",
  width: "89.947vw", // 1727/1920
  height: "400px",
  overflow: "auto"
};

export const centeringGrid: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start", // Align to start vertically
  alignItems: "center",
  position: "relative",
  height: "100%",
  width: "100%",
  minHeight: "350px"
};

export const gridContainerStyle: CSSProperties = {
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "0px",
  marginLeft: "0px",
  padding: "0px 0px 0px 0px",
  gap: "10px",
  minHeight: "100%" // Adjust based on your exact needs
  // minHeight: "calc(100% - 20px)" // from usecount positioning
};

export const userCardSetupGridStyle: CSSProperties = {
  // marginTop: "20px",
  maxWidth: "400px"
};

export const userCountStyles: CSSProperties = {
  color: "#FFFFFF",
  fontSize: "2rem",
  position: "absolute",
  right: "150px", // Align with your container's padding
  bottom: "-15px", // Align with your container's padding
  display: "flex",
  justifyContent: "end"
};
