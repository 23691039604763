import { useEffect, useMemo } from "react";
import { ref, onChildRemoved } from "firebase/database";
import { BoxingClass } from "../ui-components/helpers/CustomTypes";
import { useClassContext } from "../Contexts/ClassContext";

// Custom hook for setting up a real-time Firebase listener for child removals
export const useFBOnChildRemoved = (classId: string, setBoxingClass: React.Dispatch<React.SetStateAction<BoxingClass>>) => {
  const { db } = useClassContext();

  const classRef = useMemo(() => {
    if (db !== null) {
      return ref(db, `classes/${classId}/scores`);
    } else {
      // console.log("db is null dont do anything");
    }
  }, [classId, db]);

  useEffect(() => {
    if (classRef !== undefined) {
      const unsubscribe = onChildRemoved(classRef, (snapshot) => {
        const userId = snapshot.key;

        // Update state to reflect the removal of this user's data
        if (typeof userId === "string") {
          setBoxingClass((prevState) => {
            const updatedScores = { ...prevState[classId].scores };
            delete updatedScores[userId]; // Remove the user's data

            return {
              ...prevState,
              [classId]: {
                ...prevState[classId],
                scores: updatedScores
              }
            };
          });
          // console.log("onChildRemoved setState");
        } else {
          console.error("Invalid user ID received from Firebase:", userId);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [db, classId, setBoxingClass, classRef]);
};
