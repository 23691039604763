import initFirebase from "../../Configs/initFirebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ref, DatabaseReference, Database } from "firebase/database";
import { NavigateFunction } from "react-router-dom";

// internal imports
import regionEmailTo3Letters from "./regionEmailTo3Letters";
import { checkClassIdTaken } from "./checkClassIdTaken";
import { initializeNewClass } from "./initializeNewClass";
import fetchClassIds from "./fetchClassIds";
import connectRTDBForRegion from "./connectRTDBForRegion";
import handleCurrentUserRole from "./handleCurrentUserRole";
import fetchUserRegion from "./fetchUserRegion";
import { RegionCode, ContinuousString, Mode, BuildEnvString } from "../helpers/CustomTypes";

const googleSignIn = async (
  setClassId: React.Dispatch<React.SetStateAction<string>>,
  setFirebaseClassIDs: React.Dispatch<React.SetStateAction<string[]>>,
  setDb: React.Dispatch<React.SetStateAction<Database | null>>,
  setContinuousMode: React.Dispatch<React.SetStateAction<ContinuousString>>,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  setIsTimerRunning: React.Dispatch<React.SetStateAction<boolean>>,
  setPrevMode: React.Dispatch<React.SetStateAction<Mode>>,
  setMode: React.Dispatch<React.SetStateAction<Mode>>,
  buildEnv: BuildEnvString
) => {
  const auth = getAuth(initFirebase());
  let localFirebaseClassIDs: string[] = []; // has to be a let not const

  const getClassIDs = async (classesRef: DatabaseReference) => {
    const fetchedClassIDs = await fetchClassIds(classesRef);
    // console.log("about to set IDs: ", fetchedClassIDs);
    setFirebaseClassIDs(fetchedClassIDs); // This updates the state
    localFirebaseClassIDs = fetchedClassIDs; // This updates the local variable, allowing us to avoid needing useEffect or promises
  };

  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    if (!user) {
      console.error("No user found after sign-in.");
      return;
    }

    const foundDatabase = await connectRTDBForRegion(setDb, buildEnv);
    if (!foundDatabase) {
      console.error("No database found for region.");
      return;
    }

    // Get Firebase class IDs to see if we can get a new class or connect to an old one
    const classesRef: DatabaseReference = ref(foundDatabase, `classes/`);
    await getClassIDs(classesRef);

    const region: string | undefined = await fetchUserRegion();
    const userClassCode = regionEmailTo3Letters((region ?? "unknown") as RegionCode, user.email!);

    if (userClassCode.length < 3) {
      console.error("Error with userClassCode length: ", userClassCode);
      return;
    }

    // Check if class exists
    if (!checkClassIdTaken(userClassCode, localFirebaseClassIDs)) {
      // It doesn't exist, this is first login. Go straight to setup.
      // console.log(
      //   "class id doesn't exist, this is first login, we wipe and reset. userClassCode: ",
      //   userClassCode,
      //   "localFirebaseClassIDs: ",
      //   localFirebaseClassIDs,
      //   "function return: ",
      //   checkClassIdTaken(userClassCode, localFirebaseClassIDs),
      //   "function return inverted: ",
      //   !checkClassIdTaken(userClassCode, localFirebaseClassIDs)
      // );
      initializeNewClass(foundDatabase, userClassCode, user.email!);
      setClassId(userClassCode);
      navigate("/class/"); // TODO investigate redirection in case of new user sign in
    } else {
      // Class does exist
      // Check if the user who made the code is the current user and proceed accordingly
      await handleCurrentUserRole(
        foundDatabase,
        userClassCode,
        user.email!,
        setClassId,
        localFirebaseClassIDs,
        setContinuousMode,
        setOpenModal,
        navigate,
        setIsTimerRunning,
        setPrevMode,
        setMode
      );
    }
  } catch (error) {
    console.error("Error signing in with Google: ", error);
  }
};

export default googleSignIn;
