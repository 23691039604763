import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import initFirebase from "./Configs/initFirebase";

import StartPage from "./ui-components/01-StartPage/StartPage";
import Setup from "./ui-components/02-SetupPage/Setup";
import Boxing from "./ui-components/03-BoxingPage/Boxing";
import Continuous from "./ui-components/03.2-ContinuousPage/Continuous";
import Finish from "./ui-components/04-FinishPage/Finish";
import Login from "./ui-components/00-Login/Login";
import { Barlow600, backgroundGradient } from "./ui-components/global.styles/shared.styles";
import "./ui-components/global.styles/font.css";
import "./ui-components/global.styles/reset.css";
import { ClassProvider, useClassContext } from "./Contexts/ClassContext";

function App(): JSX.Element {
  // initFirebase();

  return (
    <BrowserRouter>
      <Helmet>
        <title>POWA Boxing Class</title>
      </Helmet>
      <ClassProvider>
        <div className="App" style={{ ...Barlow600, ...backgroundGradient }}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/start" element={<StartPage />} />
            <Route path="/class/" element={<Setup />} />
            <Route path="/class/boxing" element={<Boxing />} />
            <Route path="/class/continuous" element={<Continuous />} />
            <Route path="/class/finish" element={<Finish />} />
          </Routes>
        </div>
      </ClassProvider>
    </BrowserRouter>
  );
}

export default App;
