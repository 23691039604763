import { Container, Grid } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import ContinuousUserGrid from "./ContinuousUserGrid";
import BottomBar from "../03-BoxingPage/BottomBar";
import { containerStyles, gridContainerStyles } from "./Continuous.styles";

export default function Continuous() {
  const PopulatedContinuousUserGrid = ContinuousUserGrid();
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.log("No user is currently authenticated, going login.");
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Container style={containerStyles}>
        <Grid container spacing={2} style={gridContainerStyles}>
          {PopulatedContinuousUserGrid}
        </Grid>
      </Container>
      <BottomBar />
    </>
  );
}
