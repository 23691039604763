import { ref, update, Database } from "firebase/database";
import { Mode } from "../helpers/CustomTypes";

/**
 * Sets the mode for a given class in Firebase.
 * @param classId The ID of the class to update.
 * @param mode The mode to set ('break' or 'active').
 */

export const setFBMode = (db: Database, classId: string, mode: Mode): Promise<void> => {
  if (!db) {
    console.log("Database instance is null. db: ", db);
    return Promise.reject(new Error("Database instance is null. db: "));
  }
  if (!classId) {
    console.log("classId is null or undefined. classId: ", classId);
    return Promise.reject(new Error("classId is null or undefined."));
  }
  if (!mode) {
    console.log("mode is null or undefined. mode: ", mode);
    return Promise.reject(new Error("mode is null or undefined"));
  }

  // console.log("Setting mode in Firebase:", {
  //   db,
  //   classId,
  //   mode,
  //   timestamp: new Date().toISOString()
  // });

  const modeRef = ref(db, `classes/${classId}/meta`);

  // Get the current timestamp in an appropriate format
  const timestamp = new Date().toISOString();

  return update(modeRef, { mode, stateLastChanged: timestamp })
    .then(() => {
      // console.log(`Mode and timestamp updated to ${mode} at ${timestamp}`);
    })
    .catch((error) => {
      console.error("Error updating mode and timestamp:", error);
      throw error; // Re-throw the error for handling in calling function
    });
};
