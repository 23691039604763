import { User } from "../helpers/CustomTypes";

const sumSessions = (user: User, property: "Punches" | "Stars"): number => {
  let totalProperty = 0;

  if (user[property]) {
    const sessions = user[property] as { [session: string]: number }; // typescript is angry without this additional declaration so we can use user[property]

    Object.values(sessions).forEach((sessionValue) => {
      // console.log("adding a session: ", punchesInSession, "total: ", totalPunches)
      totalProperty += sessionValue;
    });
  }

  // console.log("returning total: ", totalProperty, property);
  return totalProperty;
};

export default sumSessions;
