import { ref, update, Database } from "firebase/database";
import { ContinuousString } from "../helpers/CustomTypes";

export const updateMetaWithClassParams = ({
  db,
  classId,
  breakDuration,
  roundDuration,
  rounds,
  currentRound,
  continuousMode
}: {
  db: Database | null;
  classId: string;
  breakDuration?: number;
  roundDuration?: number;
  rounds?: number;
  currentRound?: number;
  continuousMode?: ContinuousString;
}): Promise<void> => {
  if (db === null) {
    return Promise.reject(new Error("Database is null"));
  }

  const metaRef = ref(db, `classes/${classId}/meta`);
  const updates = {
    breakDuration,
    roundDuration,
    rounds,
    currentRound,
    continuousMode
  } as Record<string, number | string | undefined>;

  // filter out undefined values so we don't overwrite data
  Object.keys(updates).forEach((key: string) => {
    if (updates[key] === undefined) {
      delete updates[key];
    }
  });

  return update(metaRef, updates);
};
