import { ref, update, Database } from "firebase/database";

export const addMockUser = (db: Database, classId: string): Promise<void> => {
  if (!db) {
    console.log("Database instance is null. db: ", db);
    return Promise.reject(new Error("Database instance is null. db: "));
  }
  if (!classId) {
    console.log("classId is null or undefined. classId: ", classId);
    return Promise.reject(new Error("classId is null or undefined."));
  }

  const scoresRef = ref(db, `classes/${classId}/scores`);

  const userId = Math.floor(Math.random() * 100000000) + 1;
  const status = Math.floor(Math.random() * 2) === 1 ? "active" : "inactive"; // alternates
  const userName = status === "active" ? "Actrisel" : "Inactro";
  const userPunches1 = Math.floor(Math.random() * 4) + 5;
  const newUser = {
    [`user${userId}`]: {
      Name: userName,
      Punches: { "0": userPunches1, "1": userPunches1, "2": userPunches1 },
      Stars: { "0": 155, "1": 123, "2": 142 },
      // Punches: { "0": userPunches1 },
      // Stars: { "0": 155 },
      Emoji: "💻",
      Status: status,
      Intensity: 99
    }
  };

  return update(scoresRef, newUser)
    .then(() => {
      console.log(`Added user ${JSON.stringify(newUser[`user${userId}`])} to class ${classId}`);
    })
    .catch((error) => {
      console.error("Error updating mode and timestamp:", error);
      throw error; // Re-throw the error for handling in calling function
    });
};
