import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useEffect } from "react";
import { Database } from "firebase/database";

import { BoxingClass, BuildEnvString } from "../ui-components/helpers/CustomTypes";
import { useRoundsTimer } from "../Hooks/useRoundsTimer";
import { setFBMode } from "../ui-components/03-BoxingPage/setFBMode";
import { updateCurrentRoundFB } from "./updateCurrentRoundFB";
import { Mode, ContinuousString } from "../ui-components/helpers/CustomTypes";

interface ClassContextProps {
  boxingClass: BoxingClass;
  setBoxingClass: Dispatch<SetStateAction<BoxingClass>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  classId: string;
  setClassId: Dispatch<SetStateAction<string>>;
  timer: number;
  setTimer: Dispatch<SetStateAction<number>>;
  isTimerRunning: boolean;
  setIsTimerRunning: Dispatch<SetStateAction<boolean>>;
  startTimer: (overrideMode: Mode) => void;
  stopTimer: (overrideMode: Mode) => void;
  firebaseClassIDs: string[];
  setFirebaseClassIDs: Dispatch<SetStateAction<string[]>>;
  numRounds: number;
  setNumRounds: Dispatch<SetStateAction<number>>;
  roundLength: number;
  setRoundLength: Dispatch<SetStateAction<number>>;
  breakLength: number;
  setBreakLength: Dispatch<SetStateAction<number>>;
  classPunchGoal: number;
  setClassPunchGoal: Dispatch<SetStateAction<number>>;
  currentRound: number;
  setCurrentRound: (newRound: number) => void;
  isBreakTime: boolean;
  setIsBreakTime: Dispatch<SetStateAction<boolean>>;
  totalPunchesAtRoundStart: BoxingClass;
  setTotalPunchesAtRoundStart: Dispatch<SetStateAction<BoxingClass>>;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  db: Database | null;
  setDb: Dispatch<SetStateAction<Database | null>>;
  mode: Mode;
  setMode: Dispatch<SetStateAction<Mode>>;
  prevMode: Mode;
  setPrevMode: Dispatch<SetStateAction<Mode>>;
  continuousMode: ContinuousString;
  setContinuousMode: Dispatch<SetStateAction<ContinuousString>>;
  buildEnv: BuildEnvString;
  setBuildEnv: Dispatch<SetStateAction<BuildEnvString>>;
  soundsEnabled: boolean;
  setSoundsEnabled: Dispatch<SetStateAction<boolean>>;
}

const ClassContext = createContext<ClassContextProps | undefined>(undefined);

export function ClassProvider({ children }: { children: ReactNode }) {
  // console.log("ClassProvider call");
  const [boxingClass, setBoxingClass] = useState<BoxingClass>({
    ABCD: {
      // this default causes a runtime error on all classes that aren't ABCD
      // DateCreated: "2023-01-01",
      scores: {
        // ajg4560897asf3: { Name: "Sarah", Punches: 1301, Stars: 3.3, Emoji: "😎" }, // removing initial test state
        // gf997dak2kjdfu: { Name: "Jim", Punches: 1204, Stars: 1.1, Emoji: "😎" },
        // gf997dak3kjdfu: { Name: "Jim", Punches: 1204, Stars: 1.1, Emoji: "😎" },
        // gf997dak5kjdfu: { Name: "Jim", Punches: 1204, Stars: 1.1, Emoji: "😎" }
      },
      meta: {
        mode: "break"
      }
    }
  });
  const [loading, setLoading] = useState(false);
  const [classId, setClassId] = useState("");
  const [timer, setTimer] = useState<number>(0);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
  const [firebaseClassIDs, setFirebaseClassIDs] = useState<string[]>([]);
  const [numRounds, setNumRounds] = useState<number>(4);
  const [roundLength, setRoundLength] = useState<number>(10); // Default 2 minutes
  const [breakLength, setBreakLength] = useState<number>(10);
  const [classPunchGoal, setClassPunchGoal] = useState<number>(20000);

  const [localCurrentRound, setLocalCurrentRound] = useState<number>(1);
  const currentRound: number = localCurrentRound;
  const setCurrentRound = (newRound: number) => {
    setLocalCurrentRound(newRound); // update local state

    // Update Firebase
    updateCurrentRoundFB(db!, classId, newRound).catch((error) => {
      console.error("Error updating current round on Firebase:", error);
    });
  };

  const [isBreakTime, setIsBreakTime] = useState<boolean>(false);
  const [totalPunchesAtRoundStart, setTotalPunchesAtRoundStart] = useState<BoxingClass>(boxingClass);
  const [openModal, setOpenModal] = useState(false);
  const [db, setDb] = useState<Database | null>(null);

  const [mode, setMode] = useState<Mode>("break");
  const [prevMode, setPrevMode] = useState<Mode>("break");
  const [continuousMode, setContinuousMode] = useState<ContinuousString>("disabled");
  const [buildEnv, setBuildEnv] = useState<BuildEnvString>("development");
  const [soundsEnabled, setSoundsEnabled] = useState<boolean>(false);

  const startTimer = (overrideMode: Mode): void => {
    setIsTimerRunning(true);
    setMode(overrideMode); // Set the mode to the overrideMode
    setFBMode(db!, classId, overrideMode).catch((error: Error) => {
      console.error(`Error setting Firebase mode to ${overrideMode}:`, error);
    });
  };

  const stopTimer = async (overrideMode: Mode): Promise<void> => {
    try {
      setIsTimerRunning(false);
      setPrevMode(mode);
      setMode(overrideMode); // Update mode to parameter
      await setFBMode(db!, classId, overrideMode);
    } catch (error) {
      console.error(`Error setting Firebase mode to ${overrideMode}:`, error);
    }
  };

  const contextValue: ClassContextProps = {
    boxingClass,
    setBoxingClass,
    loading,
    setLoading,
    classId,
    setClassId,
    timer,
    setTimer,
    isTimerRunning,
    setIsTimerRunning,
    startTimer,
    stopTimer,
    firebaseClassIDs,
    setFirebaseClassIDs,
    numRounds,
    setNumRounds,
    roundLength,
    setRoundLength,
    breakLength,
    setBreakLength,
    classPunchGoal,
    setClassPunchGoal,
    currentRound: localCurrentRound,
    setCurrentRound,
    isBreakTime,
    setIsBreakTime,
    totalPunchesAtRoundStart,
    setTotalPunchesAtRoundStart,
    openModal,
    setOpenModal,
    db,
    setDb,
    mode,
    setMode,
    prevMode,
    setPrevMode,
    continuousMode,
    setContinuousMode,
    buildEnv,
    setBuildEnv,
    soundsEnabled,
    setSoundsEnabled
  };

  useRoundsTimer({
    classId,
    isTimerRunning,
    roundLength,
    breakLength,
    numRounds,
    isBreakTime,
    setIsBreakTime,
    currentRound,
    setCurrentRound,
    startTimer,
    stopTimer,
    setTimer,
    boxingClass,
    setBoxingClass,
    totalPunchesAtRoundStart,
    setTotalPunchesAtRoundStart,
    setOpenModal,
    db,
    setMode,
    continuousMode,
    soundsEnabled
  });
  return <ClassContext.Provider value={contextValue}>{children}</ClassContext.Provider>;
}

export function useClassContext() {
  const context = useContext(ClassContext);

  if (!context) {
    throw new Error("useClassContext must be used within a ClassProvider");
  }

  return context;
}
