import { get, DatabaseReference } from "firebase/database";

// Function to fetch current class IDs
const fetchClassIds = async (classesRef: DatabaseReference) => {
  try {
    const snapshot = await get(classesRef);
    if (snapshot.exists()) {
      // console.log('Fetched Keys in fetch function: ', Object.keys(snapshot.val()));
      return Object.keys(snapshot.val());
    } else {
      console.log("No classes found");
      return [];
    }
  } catch (error) {
    console.error("Error fetching class IDs:", error);
    return [];
  }
};

export default fetchClassIds;
