export const checkClassIdTaken = (newClassId: string, existingClassIds: string[]): boolean => {
  // console.log("in chechClassIdTaken.", newClassId, existingClassIds, "function return: ", existingClassIds.includes(newClassId));
  return existingClassIds.includes(newClassId);
};

// Example usage
// const existingClassIds: string[] = ['ABCD', 'EFGH', 'IJKL']; // Example array of existing class IDs
// const newClassId: string = 'EFGH';

// const isDuplicate: boolean = checkClassIdTaken(newClassId, existingClassIds);
// console.log(isDuplicate); // Outputs true if newClassId is in existingClassIds, false otherwise
