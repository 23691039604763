import { Grid } from "@mui/material"; // Assuming MUI components

import { useClassContext } from "../../Contexts/ClassContext";
import { User } from "../helpers/CustomTypes"; // Import the User type
import calculateGridStylesAndHeightFinish from "./CalcGridStylesAndHeightFinish";

import { userCardBoxingGridStyles } from "../03-BoxingPage/Boxing.styles";
import UserCardFinish from "../MUIComponents/04-UserCardFinish/UserCardFinish";

const FinishUserGrid = () => {
  const { classId, boxingClass } = useClassContext();
  const scores = boxingClass[classId]?.scores;
  const userCount = scores ? Object.keys(scores).length : 0;

  return (
    <>
      {classId &&
        scores &&
        Object.entries(scores).map(([userId, user]: [string, User]) => {
          const { gridSizes, height, internalStyle } = calculateGridStylesAndHeightFinish(userCount);

          return (
            <Grid item key={userId} style={{ ...userCardBoxingGridStyles, height: height }} {...gridSizes}>
              <UserCardFinish styles={internalStyle} user={{ ...user }} />
            </Grid>
          );
        })}
    </>
  );
};

export default FinishUserGrid;
