import { CSSProperties } from "react";

export const modalStyle: CSSProperties = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 960,
  maxWidth: 960,
  height: 630,
  maxHeight: 630,
  background: "#3B3B3B",
  border: "12px solid #262626",
  borderRadius: "5px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  outline: "none"
  // p: 4
};

export const roundedCorners: CSSProperties = {
  borderRadius: "5px",
  border: "none",
  height: "100%"
};

export const modalTitle: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600,
  fontSize: "75px",
  letterSpacing: "0.46px",
  background: "#1E1E1E",
  color: "#FFFFFF",
  padding: "35px 50px 45px 50px",
  position: "relative",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};

export const joinNowClassId: CSSProperties = {
  display: "flex",
  justifyContent: "end",
  gap: "10px",
  minWidth: "440px"
};

export const joinNow: CSSProperties = {
  filter: "brightness(70%)"
};

export const xButtonContainer: CSSProperties = {
  position: "absolute",
  top: -42,
  right: -5,
  padding: "10px"
};

export const xButton: CSSProperties = {
  height: "30px",
  color: "#FFFFFF",
  filter: "brightness(70%)",
  cursor: "pointer"
};

export const modalContent: CSSProperties = {
  color: "#3B3B3B",
  height: "450px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "40px 0px 60px 0px", // top, right , bottom, left
  position: "relative"
};

export const punchGoalContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  margin: "20px 0px 20px 0px"
};

export const punchGoalLabel: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontSize: "40px",
  fontWeight: "700",
  color: "#FFFFFF"
};

export const punchGoalInput: CSSProperties = {
  height: "60px",
  width: "490px",
  background: "rgba(0, 0, 0, 0.32)",
  border: "none",
  borderRadius: "4px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  fontSize: "40px",
  color: "white",
  marginTop: "10px",
  padding: "0px 10px"
};

export const resumeButtonStyle: CSSProperties = {
  background: "#f2662D"
};
export const quitButtonStyle: CSSProperties = {
  background: "rgba(241, 67, 67, 0.85)" // F14343
};

export const buttonStyle: CSSProperties = {
  width: "500px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px",
  fontFamily: "Barlow, sans-serif",
  fontWeight: 800
};

export const volumeDiv: CSSProperties = {
  position: "absolute", // Positioning volume div absolutely
  right: "2%",
  bottom: "0%",
  transform: "translateY(-50%)", // Adjust vertical alignment
  width: "43px", // Set width to enclose the icon
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
};

export const volumeIcon: CSSProperties = {
  height: "40px"
};
