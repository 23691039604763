import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { useClassContext } from "../../../Contexts/ClassContext";

// import workoutCompleted from "../../04-FinishPage/workoutCompleted";
import clearClassScores from "../../00-Login/clearClassScores";
import logWorkoutCompleted from "../../04-FinishPage/logWorkoutCompleted";
import Modal_x_button from "../../../images/Modal_x_button.png";

import { buttonStyle } from "./PauseModal.styles";
import { modalStyle, modalContent, modalTitle, confirmationMessage, confirmButtonStyle, cancelButtonStyle } from "./ConfirmModal.styles";

import { roundedCorners, xButton, xButtonContainer } from "./PauseModal.styles";
import writeContStatusToFirestore from "./writeContStatusToFirestore";

type ConfirmModalProps = {
  isOpen: boolean;
  onDismiss: (isOpen: boolean) => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { classId, setOpenModal, stopTimer, setTimer, setIsBreakTime, setCurrentRound, setTotalPunchesAtRoundStart, db } =
    useClassContext();
  const { isOpen, onDismiss } = props;
  const navigate = useNavigate();

  // console.log("in ConfirmModal. openConfirmModal: ", openConfirmModal);

  const handleClose = () => {
    onDismiss(false);
  };

  const handleConfirm = () => {
    // console.log("cont mode: ", continuousMode);
    onDismiss(false);
    setOpenModal(false);
    // setMode("break"); // stopTimer sets mode to completed
    stopTimer("completed");
    setTimer(0);
    setIsBreakTime(false);
    setCurrentRound(1);
    setTotalPunchesAtRoundStart({}); // reset punches in local state so we can start fresh on the next round/class
    clearClassScores(db!, classId);
    navigate("/class");
    logWorkoutCompleted(classId);
    // writeContStatusToFirestore("disabled");
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 2 }}
      >
        <Box sx={modalStyle}>
          <div style={roundedCorners}>
            <div style={modalTitle}>
              <span>{"CONFIRM"}</span>

              <div style={xButtonContainer}>
                <img onClick={handleClose} style={xButton} src={Modal_x_button} alt="" />
              </div>
            </div>
            <div style={modalContent}>
              <h3 style={confirmationMessage}>Are you sure you want to delete all user data for this class?</h3>
              <Button onClick={handleConfirm} style={{ ...buttonStyle, ...confirmButtonStyle }}>
                CONFIRM
              </Button>
              <Button onClick={handleClose} style={{ ...buttonStyle, ...cancelButtonStyle }}>
                CANCEL
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmModal;
