import { ref, remove, Database } from "firebase/database";

/**
 * Deletes a user from a specified class.
 * @param classId The ID of the class from which the user will be deleted.
 * @param userId The ID of the user to delete.
 * @returns A promise that resolves when the user has been successfully deleted.
 */
export const deleteUser = (db: Database, classId: string, userId: string): Promise<void> => {
  const userRef = ref(db, `classes/${classId}/scores/${userId}`);

  return remove(userRef)
    .then(() => {
      // Optionally, handle success (e.g., log a message)
      // console.log(`User ${userId} deleted from class ${classId}`);
    })
    .catch((error) => {
      console.error("Error deleting user:", error);
      throw error; // Re-throw the error for handling in calling function
    });
};
