// don't change this function name, mui internally uses it
export function formatValueText(value: number, type: string): string {
  if (type === "rounds") {
    return `${value} ROUNDS`;
  } else if (type === "time") {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return minutes > 0 ? `${minutes} MINUTES ${seconds > 0 ? `${seconds}SECONDS` : ""}`.trim() : `${seconds}SECONDS`;
  } else {
    return `${value}`;
  }
}
