import { CSSProperties } from "react";

export const loginPageStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column", // This is correctly typed now
  gap: "20px",
  height: "100vh",
  paddingTop: "2vh",
  color: "#FFFFFF"
};

export const loginHeader: CSSProperties = {
  fontSize: "40px",
  width: "500px",
  display: "flex",
  justifyContent: "center"
};
export const powaLogoContainer: CSSProperties = {
  maxHeight: "55vh"
};
export const powaLogo: CSSProperties = {
  maxHeight: "60vh"
};
export const buttonStyle: CSSProperties = {
  background: "#f2662D",
  width: "400px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 700
};

export const contactUs: CSSProperties = {
  color: "#FFFFFF",
  background: "#f2662D",
  width: "400px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 700
  //positioning
};
