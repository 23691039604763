import { CSSProperties } from "react";

export const startPageStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
  height: "100vh",
  paddingTop: "4vh",
  color: "#FFFFFF"
};
export const powaLogoContainer: CSSProperties = {
  maxHeight: "55vh"
};
export const powaLogo: CSSProperties = {
  maxHeight: "60vh"
};

export const inputAndButtonContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "20px"
};

export const classIdInputLabel: CSSProperties = {
  width: "400px",
  fontSize: "20px",
  fontWeight: 800,
  letterSpacing: "2px",
  color: "#D9D9D9",
  display: "flex",
  paddingTop: "20px"
};

export const classIdInput: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 800,
  fontSize: "20px",
  letterSpacing: "2px",
  background: "#D9D9D9",
  width: "390px",
  height: "40px",
  borderRadius: "6px",
  paddingLeft: "10px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
  marginBottom: "20px",
  color: "rgba(86, 86, 86, 0.8)"
};

export const buttonStyle: CSSProperties = {
  background: "#f2662D",
  width: "400px",
  height: "70px",
  borderRadius: "8px",
  fontSize: "24px",
  letterSpacing: "2px",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 700
};
