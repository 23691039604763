// src/firebaseOperations/updateCurrentRound.ts
import { ref, update, Database } from "firebase/database";

/**
 * Updates the current round in Firebase.
 * @param db Firebase Database instance
 * @param classId The ID of the class
 * @param newRound The new round number
 */
export const updateCurrentRoundFB = (db: Database, classId: string, newRound: number) => {
  if (db) {
    const updates: Record<string, any> = {};
    updates[`classes/${classId}/meta/currentRound`] = newRound;
    return update(ref(db), updates);
  } else {
    return Promise.reject(new Error("Database instance is not available."));
  }
};
