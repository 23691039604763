import { logEvent } from "firebase/analytics";
import { getWebAnalytics } from "../../Configs/initFirebase";

const logWorkoutCompleted = (classId: string): void => {
  logEvent(getWebAnalytics(), "workout_completed", {
    classId: classId
  });
};

export default logWorkoutCompleted;
