import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Database } from "firebase/database";
import useSound from "use-sound";

import { BoxingClass, ContinuousString } from "../ui-components/helpers/CustomTypes";
import { setFBMode } from "../ui-components/03-BoxingPage/setFBMode";
import { Mode } from "../ui-components/helpers/CustomTypes";
import workoutCompleted from "../ui-components/04-FinishPage/workoutCompleted";

import alert_sound from "../sounds/censor-beep-1sec.mp3";

type UseRoundsTimerParams = {
  classId: string;
  isTimerRunning: boolean;
  roundLength: number;
  breakLength: number;
  numRounds: number;
  isBreakTime: boolean;
  setIsBreakTime: React.Dispatch<React.SetStateAction<boolean>>;
  currentRound: number;
  setCurrentRound: (newRound: number) => void;
  startTimer: (overrideMode: Mode) => void;
  stopTimer: (overrideMode: Mode) => void;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  boxingClass: BoxingClass;
  setBoxingClass: React.Dispatch<React.SetStateAction<BoxingClass>>;
  totalPunchesAtRoundStart: BoxingClass;
  setTotalPunchesAtRoundStart: React.Dispatch<React.SetStateAction<BoxingClass>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  db: Database | null;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  continuousMode: ContinuousString;
  soundsEnabled: boolean;
};

export const useRoundsTimer = ({
  classId,
  isTimerRunning,
  roundLength,
  breakLength,
  numRounds,
  isBreakTime,
  setIsBreakTime,
  currentRound,
  setCurrentRound,
  stopTimer,
  setTimer,
  boxingClass,
  setTotalPunchesAtRoundStart,
  setOpenModal,
  db,
  setMode,
  continuousMode,
  soundsEnabled
}: UseRoundsTimerParams) => {
  const navigate = useNavigate();

  const [play] = useSound(alert_sound, {
    interrupt: true,
    soundEnabled: soundsEnabled
  });

  const handleFinish = async (intervalId: NodeJS.Timeout): Promise<void> => {
    clearInterval(intervalId);

    workoutCompleted({
      setOpenModal,
      stopTimer,
      setMode,
      setTimer,
      setIsBreakTime,
      setCurrentRound,
      setTotalPunchesAtRoundStart,
      navigate,
      classId
    });
  };

  useEffect(() => {
    if (continuousMode === "enabled") {
      return;
    }

    let intervalId: NodeJS.Timeout;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer: number) => {
          if (prevTimer <= 1) {
            if (isBreakTime) {
              // Transition from break to round
              play();
              setFBMode(db!, classId, "active");
              // Adding setTotalPunches here in transition from break to round.
              // This should fix bug that sets user punches to zero at one second into the break
              setTotalPunchesAtRoundStart(boxingClass);

              // Calculate the new round first
              const newRound = currentRound < numRounds ? currentRound + 1 : currentRound;
              setCurrentRound(newRound); // Now passing the new round directly

              setIsBreakTime(false);
              setMode("active");
              return roundLength; // Set timer for round
            } else {
              // Transition from round to break
              play();
              setFBMode(db!, classId, "break");

              if (currentRound === numRounds) {
                // We don't want a break after the last round, boxing is over
                handleFinish(intervalId);
                return 0;
              }
              setIsBreakTime(true);
              setMode("break");

              return breakLength; // Set timer for break
            }
          }

          return prevTimer - 1; // nothing changes this second, just decrement timer
        });
      }, 1000); // we check every second
    }

    return () => clearInterval(intervalId);
  }, [isTimerRunning, roundLength, breakLength, numRounds, isBreakTime, currentRound]);
};
