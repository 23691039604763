import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { useClassContext } from "../../../Contexts/ClassContext";
// import { useConfirmModalContext } from "../../../Contexts/ConfirmModalContext";

import workoutCompleted from "../../04-FinishPage/workoutCompleted";
import ConfirmModal from "./ConfirmModal";

import { buttonStyle, resumeButtonStyle, quitButtonStyle } from "./PauseModal.styles";

export const GetPauseModalButtons = (pathname: string): JSX.Element | null => {
  const {
    setOpenModal,
    isTimerRunning,
    startTimer,
    stopTimer,
    classId,
    setTimer,
    setIsBreakTime,
    setCurrentRound,
    setBoxingClass,
    prevMode,
    setMode,
    setTotalPunchesAtRoundStart
  } = useClassContext();
  // const { openConfirmModal, setOpenConfirmModal } = useConfirmModalContext();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const navigate = useNavigate();

  const handleResume = () => {
    startTimer(prevMode);
    setOpenModal(false);
  };

  const handleEndWorkout = async (): Promise<void> => {
    // should match timer's end class function
    workoutCompleted({
      setOpenModal,
      stopTimer,
      setMode,
      setTimer,
      setIsBreakTime,
      setCurrentRound,
      setTotalPunchesAtRoundStart,
      navigate,
      classId
    });
  };

  const handleBackToSetup = (): void => {
    // rounds mode
    setOpenModal(false);
    stopTimer("break");
    setMode("break");
    setTimer(0);
    setIsBreakTime(false);
    setCurrentRound(1);
    setBoxingClass((prevClass) => ({
      ...prevClass,
      [classId]: {
        ...prevClass[classId],
        scores: {} // Correctly resetting scores to an empty object
      }
    }));
    setTotalPunchesAtRoundStart({});
    navigate("/class/");
  };

  const handleDeleteData = (): void => {
    // console.log("setting confirm modal to true: ", openConfirmModal);
    setOpenConfirmModal(true);
  };

  const handleQuit = (): void => {
    setOpenModal(false);
    stopTimer("break");
    setMode("break");
    setTimer(0);
    setIsBreakTime(false);
    setCurrentRound(1);
    setBoxingClass({});
    setTotalPunchesAtRoundStart({});
    navigate("/login/");
  };

  switch (true) {
    case pathname.endsWith("/boxing"):
      return (
        <>
          <Button onClick={handleResume} style={{ ...buttonStyle, ...resumeButtonStyle }} variant="contained">
            {isTimerRunning ? "PAUSE" : "RESUME"} WORKOUT
          </Button>
          <Button onClick={handleEndWorkout} style={{ ...buttonStyle, ...quitButtonStyle }} variant="contained">
            END WORKOUT
          </Button>
        </>
      );
    case pathname.endsWith("/finish"):
      return (
        <>
          <Button onClick={handleBackToSetup} style={{ ...buttonStyle, ...resumeButtonStyle }} variant="contained">
            CLASS SETUP
          </Button>
          <Button onClick={handleQuit} style={{ ...buttonStyle, ...quitButtonStyle }} variant="contained">
            QUIT
          </Button>
        </>
      );
    case pathname.endsWith("/continuous"):
      // Customize the continuous mode content as needed
      return (
        <>
          <Button onClick={handleResume} style={{ ...buttonStyle, ...resumeButtonStyle }} variant="contained">
            {isTimerRunning ? "PAUSE" : "RESUME"} WORKOUT
          </Button>
          <Button onClick={handleDeleteData} style={{ ...buttonStyle, ...quitButtonStyle }} variant="contained">
            END SESSION
          </Button>
          <ConfirmModal isOpen={openConfirmModal} onDismiss={setOpenConfirmModal} />
        </>
      );
    default:
      return null;
  }
};
