// UserCard.js // https://www.youtube.com/watch?v=M75MUZ1zVYM guide for basic card
// https://www.youtube.com/watch?v=5cvg2qZwYrI mui usage
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { UserCardProps } from "../../helpers/CustomTypes";
import intensityToRGBA from "../../helpers/intensityToRGBA";
import { CardHeader } from "@mui/material";
import Box from "@mui/material/Box";
import Glove_image from "../../../images/Glove_image.png";

import {
  MUICardContentStyle,
  iconCountGap,
  nameDisplayCardHeader,
  nameDisplayText,
  roundPunches,
  totalPunchesAndStars,
  typographyStyle,
  userCardBoxingStyles,
  boxingGlove
} from "./UserCardBoxing.styles";
import { inlineContainer } from "../02-UserCardSetup/UserCardSetup.styles";

export default function UserCardBoxing({ user, styles }: UserCardProps) {
  if (!user || !user?.Name) {
    // sometimes the mobile app writes stuff after a user has been deleted, leading to a blank user
    return <></>;
  }

  const nameSize = styles?.formatting?.name ? { fontSize: styles.formatting.name } : {}; // this sets the variable as object with property for scaling font size
  const roundPunchesSize = styles?.formatting?.roundPunches ? { fontSize: styles.formatting.roundPunches } : {};
  const totalPunchesAndStarsSize = styles?.formatting?.totalPunchesandStars ? { fontSize: styles.formatting.totalPunchesandStars } : {};
  const gloveSize = styles?.formatting?.gloveSize ? { height: styles.formatting.gloveSize } : {};
  const textIntensity = { textShadow: `10px 7px 30px ${intensityToRGBA(user.Intensity)}` };
  const emojiSize = styles?.formatting?.emojiSize ? { fontSize: styles.formatting.emojiSize } : {};
  const nameUpperCase = typeof user?.Name === "string" ? user.Name.toUpperCase() : String(user?.Name || "");

  const userRoundPunches = user.RoundPunches ?? "0";
  const userPunches = user.Punches?.[0] ?? "0";
  const userStars = user?.Stars?.[0] ?? 0;

  return (
    <Card style={userCardBoxingStyles} elevation={6}>
      <CardHeader
        style={{ ...nameDisplayCardHeader, ...nameSize }}
        titleTypographyProps={{ sx: { ...nameDisplayText, ...nameSize } }}
        title={
          <span style={inlineContainer}>
            {nameUpperCase} <span style={emojiSize}>{user?.Emoji}</span>
          </span>
        }
      />
      <Typography style={typographyStyle} variant="body2" color="text.secondary">
        <CardContent style={MUICardContentStyle}>
          <h1 title="Round Punches" style={{ ...roundPunches, ...roundPunchesSize, ...textIntensity }}>
            {userRoundPunches}
          </h1>
          <div style={{ ...totalPunchesAndStars, ...totalPunchesAndStarsSize }}>
            <Box style={iconCountGap} component="span">
              <img style={{ ...gloveSize, ...boxingGlove }} src={Glove_image} alt="Boxing Glove" />
              {userPunches}
            </Box>
            <Box style={iconCountGap} component="span">
              <span>✮</span>
              {userStars}
            </Box>
          </div>
        </CardContent>
      </Typography>
    </Card>
  );
}
