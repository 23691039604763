import { CSSProperties } from "react";

export const containerStyles: CSSProperties = {
  // paddingTop: "10px",
  // marginTop: "20px",
  maxWidth: "100%",
  paddingLeft: "0px",
  paddingRight: "0px"
};

export const gridContainerStyles: CSSProperties = {
  display: "flex",
  alignItems: "center", // Change from center to stretch to allow children to fill the container height
  justifyContent: "center",
  margin: "0px",
  width: "100%"
};
