import DiscreteSlider from "../MUIComponents/DiscreteSlider";
import ContinuousSwitch from "../MUIComponents/Switch";
import { displayTimeStandard } from "./DisplayTimeStandard";

import { useClassContext } from "../../Contexts/ClassContext";

import volume_on from "../../images/volume_on.png";
import volume_off from "../../images/volume_off.png";

import {
  slidersContainerStyle,
  numberDisplay,
  rowContainerStyle,
  sliderLabel,
  unitsDisplay,
  smoothTransition,
  volumeIcon,
  volumeDiv
} from "./Sliders.styles";

export default function Sliders() {
  const {
    numRounds,
    setNumRounds,
    roundLength,
    setRoundLength,
    breakLength,
    setBreakLength,
    continuousMode,
    soundsEnabled,
    setSoundsEnabled
  } = useClassContext();

  const handleRoundsChange = (event: Event, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setNumRounds(newValue);
  };

  const handleRoundLengthChange = (event: Event, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setRoundLength(newValue);
  };

  const handleBreakLengthChange = (event: Event, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setBreakLength(newValue);
  };

  const handleSoundsEnable = (): void => {
    soundsEnabled ? setSoundsEnabled(false) : setSoundsEnabled(true);
  };

  return (
    <div style={slidersContainerStyle}>
      {continuousMode === "disabled" && (
        <div style={volumeDiv} onClick={handleSoundsEnable}>
          <img style={volumeIcon} src={soundsEnabled ? volume_on : volume_off} alt="Sound Enable/Disable" />
        </div>
      )}
      <div style={rowContainerStyle}>
        <h3 style={sliderLabel}>
          <ContinuousSwitch /> {continuousMode === "enabled" ? "OPEN" : "ROUNDS"}
        </h3>
      </div>
      {/* <div style={continuousMode === "enabled" ? greyFilterStyle : {}}> */}
      {continuousMode === "disabled" && (
        <div key="disabled" style={smoothTransition}>
          <div style={rowContainerStyle}>
            <span style={numberDisplay}>{numRounds}</span>
            <span style={unitsDisplay}>{numRounds === 1 ? "ROUND" : "ROUNDS"}</span>
            <DiscreteSlider
              label="Number of Rounds"
              value={numRounds}
              type={"rounds"}
              step={1}
              min={1}
              max={15}
              onChange={handleRoundsChange}
            />
            <h3 style={sliderLabel}>NUMBER OF ROUNDS</h3>
          </div>
          <div style={rowContainerStyle}>
            <span style={numberDisplay}>{displayTimeStandard(roundLength)}</span>
            <span style={unitsDisplay}>{roundLength === 60 ? "MINUTE" : roundLength > 60 ? "MINUTES" : "SECONDS"}</span>
            <DiscreteSlider
              label="Round Length"
              value={roundLength}
              type={"time"}
              step={5}
              min={5}
              max={180}
              onChange={handleRoundLengthChange}
            />
            <h3 style={sliderLabel}>ROUND DURATION</h3>
          </div>
          <div style={rowContainerStyle}>
            <span style={numberDisplay}>{displayTimeStandard(breakLength)}</span>
            <span style={unitsDisplay}>{breakLength === 60 ? "MINUTE" : breakLength > 60 ? "MINUTES" : "SECONDS"}</span>
            <DiscreteSlider
              label="Break Length"
              value={breakLength}
              type={"time"}
              step={5}
              min={5}
              max={180}
              onChange={handleBreakLengthChange}
            />
            <h3 style={sliderLabel}>BREAK DURATION</h3>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
}
