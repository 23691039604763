export const displayTimeDecrement = (timer: number): string => {
  if (timer <= 0) {
    return "0:00"; // Return 0:00 instead of going negative
  }

  // Subtract 1 second from the timer first to fix edge case at 0:59 and avoid 1:00-1
  timer -= 1;

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
