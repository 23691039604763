// UserCard.js // https://www.youtube.com/watch?v=M75MUZ1zVYM guide for basic card
// https://www.youtube.com/watch?v=5cvg2qZwYrI mui usage
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useClassContext } from "../../../Contexts/ClassContext";
import { UserCardProps } from "../../helpers/CustomTypes";
import { CardHeader } from "@mui/material";
import Box from "@mui/material/Box";
import Glove_image from "../../../images/Glove_image.png";

import sumSessions from "../../03.2-ContinuousPage/sumSessions";
import intensityToRGBA from "../../helpers/intensityToRGBA";

import {
  MUICardContentStyle,
  iconCountGap,
  nameDisplayCardHeader,
  nameDisplayText,
  roundPunches,
  totalPunchesAndStars,
  typographyStyle,
  userCardBoxingStyles,
  boxingGlove
} from "./UserCardFinish.styles";
import { inlineContainer } from "../02-UserCardSetup/UserCardSetup.styles";

export default function UserCardFinish({ user, styles }: UserCardProps) {
  const { mode } = useClassContext();

  const nameSize = styles?.formatting?.name ? { fontSize: styles.formatting.name } : {}; // this sets the variable as object with property for scaling font size
  const roundPunchesSize = styles?.formatting?.roundPunches ? { fontSize: styles.formatting.roundPunches } : {};
  const totalPunchesAndStarsSize = styles?.formatting?.totalPunchesandStars ? { fontSize: styles.formatting.totalPunchesandStars } : {};
  const gloveSize = styles?.formatting?.gloveSize ? { height: styles.formatting.gloveSize } : {};
  const emojiSize = styles?.formatting?.emojiSize ? { fontSize: styles.formatting.emojiSize } : {};
  const textIntensity = { textShadow: `10px 7px 30px ${intensityToRGBA(user.Intensity)}` };

  const nameUpperCase = typeof user?.Name === "string" ? user.Name.toUpperCase() : String(user?.Name || "");

  if (!user || !user?.Name) {
    // sometimes the mobile app writes stuff after a user has been deleted, leading to a blank user
    return <></>;
  }

  // we do this sum so that we become boxing mode agnostic. if in rounds mode, it will just sum the one entry and that's easier than bothering with checking if continuousMode is enabled in state
  user.Punches = user.Punches || { "0": 0 };
  user.Punches[0] = user.Punches[0] ?? 0; // set to zero if null or undefined using nullish coalescing operator
  const userPunches = sumSessions(user, "Punches");

  user.Stars = user.Stars || { "0": 0 };
  user.Stars[0] = user.Stars[0] ?? 0; // set to zero if null or undefined using nullish coalescing operator
  const userStars = sumSessions(user, "Stars");

  return (
    <Card style={userCardBoxingStyles} elevation={6}>
      <CardHeader
        style={{ ...nameDisplayCardHeader, ...nameSize }}
        titleTypographyProps={{ sx: { ...nameDisplayText, ...nameSize } }}
        title={
          <span style={inlineContainer}>
            {nameUpperCase} <span style={emojiSize}>{user?.Emoji}</span>
          </span>
        }
      />
      <Typography style={typographyStyle} variant="body2" color="text.secondary">
        <CardContent style={MUICardContentStyle}>
          <Box
            component="span"
            style={
              mode === "active" ? { ...roundPunches, ...roundPunchesSize, ...textIntensity } : { ...roundPunches, ...roundPunchesSize }
            }
          >
            <img style={{ ...gloveSize, ...boxingGlove }} src={Glove_image} alt="Boxing Glove" />
            {userPunches}
          </Box>
          <div style={{ ...totalPunchesAndStars, ...totalPunchesAndStarsSize }}>
            <Box style={iconCountGap} component="span">
              <span>✮</span>
              {userStars}
            </Box>
            <Box style={iconCountGap} component="span"></Box>
          </div>
        </CardContent>
      </Typography>
    </Card>
  );
}
