import { CSSProperties } from "react";

export const nameDisplayCardHeader: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  // paddingBottom: "0px",
  lineHeight: "0.8",
  padding: "0px",
  background: "#1E1E1E", // 1E1E1E
  display: "flex",
  alignItems: "center"
};
export const nameDisplayText: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  // fontSize: "3.2rem",
  lineHeight: "0.8",
  padding: "10px 0px 10px 5px", // top, right , bottom, left
  color: "#FFFFFF" // FFFFFF white
};
export const MUICardContentStyle: CSSProperties = {
  padding: "5px 8px 5px 8px", // top right bottom left
  display: "flex",
  flex: "1 1 100%",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  background: "#3B3B3B"
};
export const typographyStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flex: "1 1 100%"
};
export const roundPunches: CSSProperties = {
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600,
  minWidth: "60%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  paddingBottom: "10px",
  lineHeight: "0.6",
  color: "#FFFFFF",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" //x offset y offset radius of blur
  //innerShadow: blur 4, y 4 25%
};
export const totalPunchesAndStars: CSSProperties = {
  minWidth: "30%",
  minHeight: "65%",
  display: "flex",
  // gap: "10px",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "space-between",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: "600",
  filter: "brightness(70%)",

  color: "#FFFFFF",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};
export const boxingGlove: CSSProperties = {
  marginTop: "3%"
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" //trying to apply shadow, currently doesn't work as it treats the glove as a rectangle. Transparency isn't recognized
};

export const iconCountGap: CSSProperties = {
  display: "flex",
  gap: "6px",
  alignItems: "center"
};

export const userCardBoxingStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  backgroundColor: "transparent",
  height: "100%" // Ensures the user card takes the full height of its parent (the grid item)
};
