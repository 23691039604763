import { RegionCode } from "../helpers/CustomTypes";
// Helper function to shift a character by a specified number of positions
const shiftChar = (char: string, shift: number) => {
  const ascii = char.charCodeAt(0);
  let shiftedChar;

  if (ascii >= 65 && ascii <= 90) {
    // 'A'-'Z'
    shiftedChar = String.fromCharCode(((ascii - 65 + shift) % 26) + 65);
  } else {
    shiftedChar = char; // Non-alphabetic characters are unchanged
  }

  return shiftedChar.toUpperCase(); // Convert to uppercase for uniformity
};

// Function to convert a character to a letter code
const charToLetterCode = (char: string) => {
  const ascii = char.charCodeAt(0);
  let normalizedIndex;

  if (ascii >= 48 && ascii <= 57) {
    // '0'-'9'
    normalizedIndex = ascii - 48;
  } else if (ascii >= 65 && ascii <= 90) {
    // 'A'-'Z'
    normalizedIndex = ascii - 65;
  } else if (ascii >= 97 && ascii <= 122) {
    // 'a'-'z'
    normalizedIndex = ascii - 97;
  } else {
    normalizedIndex = 0; // Default case, should not happen with given constraints
  }

  // Map the normalized index to 'A'-'Z'
  const letterCode = String.fromCharCode((normalizedIndex % 26) + 65);
  return letterCode;
};

// Function to infer the region based on the region code
function inferRegion(regionCode: RegionCode) {
  switch (regionCode.toLowerCase()) {
    case "na":
      return "A";
    case "eu":
      return "B";
    case "as":
      return "C";
    default:
      return "A"; // Default to 'A' or throw an error depending on the use case
  }
}

// Main function to convert region and email to a 3-letter code
const regionEmailTo3Letters = (region: RegionCode, email: string) => {
  const regionPrefix = inferRegion(region);

  // Take the first 3 characters from the email and convert each to a specified letter code
  const emailSegment = email.slice(0, 3).split("").map(charToLetterCode).join("");

  // Shift each character in the result by 4 positions
  const shiftedSegment = emailSegment
    .split("")
    .map((char) => shiftChar(char, 4))
    .join("");

  // Return the combined result
  return regionPrefix + shiftedSegment;
};

// Testing the function with different inputs
// console.log(regionEmailTo3Letters("na", "AXYZ@domain.com")); // Should output "AEBCD"
// console.log(regionEmailTo3Letters("eu", "ABCD@domain.com")); // Should output "EFGH"
// console.log(regionEmailTo3Letters("as", "YYYY@domain.com")); // Should output "CCCC"

export default regionEmailTo3Letters;
