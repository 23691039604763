import { CSSProperties } from "react";

export const Barlow600: CSSProperties = {
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600
};

export const Barlow700: CSSProperties = {
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 700
};

export const BarlowSemi600: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600
};

export const Barlow800: CSSProperties = {
  fontFamily: "Barlow, sans-serif",
  fontWeight: 800
};

export const backgroundGradient: CSSProperties = {
  backgroundImage: "linear-gradient(180deg, #35363A, #101010)",
  minHeight: "100vh",
  height: "100%",
  width: "100%"
};
