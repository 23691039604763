import initFirebase from "../../Configs/initFirebase";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

// This function fetches the user's region from Firestore
const fetchUserRegion = async (): Promise<string | undefined> => {
  const firestore = getFirestore(initFirebase());
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user || !user.email) {
    console.error("No user signed in or user email is null");
    return undefined;
  }

  try {
    const docRef = doc(firestore, "gyms", user.email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data().region;
    } else {
      // Document doesn't exist, create it with UID
      await setDoc(docRef, { uid: user.uid, region: "na" });
      return "na";
    }
  } catch (error) {
    console.error("Error reading from Firestore: ", error);
  }
  return undefined;
};

export default fetchUserRegion;
