import { ref, get, Database } from "firebase/database";
import { NavigateFunction } from "react-router-dom";

import clearClassScores from "./clearClassScores";
import { ContinuousString, Mode } from "../helpers/CustomTypes";
import { setFBMode } from "../03-BoxingPage/setFBMode";

export default async function assessClearScores(
  db: Database,
  classId: string,
  setContinuousMode: (mode: ContinuousString) => void,
  setOpenModal: (val: boolean) => void,
  navigate: NavigateFunction,
  setIsTimerRunning: (val: boolean) => void,
  setPrevMode: (mode: Mode) => void,
  setMode: (mode: Mode) => void
): Promise<void> {
  const classMetaRef = ref(db, `classes/${classId}/meta`);

  try {
    const snapshot = await get(classMetaRef);
    if (!snapshot.exists()) {
      // console.log(`Class ${classId} does not exist or has no metadata.`);
      return;
    }

    const classMeta = snapshot.val();
    const stateLastChanged = classMeta.stateLastChanged;

    if (!stateLastChanged) {
      // console.log(`Class ${classId} has no stateLastChanged timestamp.`);
      return;
    }

    const continuousMode = classMeta.continuousMode;
    const classMode = classMeta.mode;
    if (continuousMode === "enabled") {
      // console.log(`Class: ${classId} is in continuous mode, not clearing the scores`);
      setContinuousMode("enabled");
      if (classMode === "completed") {
        navigate("/class");
      } else if (classMode === "active") {
        // a user closed the continuous window when it was still active, we can go straight in
        // TODO add a check for num users or add new mode for "initializing" if someone turns
        // continuousmode on and refreshes
        setOpenModal(false);
        navigate("/class/continuous");
      } else {
        // mode is pause or break, we open modal
        setOpenModal(true);
        navigate("/class/continuous");
      }
      // console.log("classId: ", classId, "db: ", db); // checking for db as ref in setFBMode in
      // stopTimer can throw a:
      // Error checking class timestamp or updating scores: TypeError: Cannot read properties of null

      try {
        // this is approximating stopTimer as we don't have db yet so we are manually setting it using
        // the local db argument
        setIsTimerRunning(false);
        setPrevMode("active");
        if (classMode !== "active") {
          setMode("pause"); // Update mode to parameter
          setFBMode(db, classId, "pause");
        } else {
          setMode("active");
        }
      } catch (error) {
        console.error(`Error setting Firebase mode to pause:`, error);
      }
      // we want to exit, the rest of the function is for non-continuous mode classes
      return;
    }

    const currentMode = classMeta.mode;
    const lastChangedDate = new Date(stateLastChanged).getTime();
    const currentDate = new Date().getTime();
    const fiveMinutesInMs = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (currentDate - lastChangedDate > fiveMinutesInMs || currentMode === "completed") {
      // console.log("about to clear scores: ", db);
      clearClassScores(db, classId);
    } else {
      // console.log(`Class: ${classId} is not completed or it hasn't been 5 minutes`);
    }
    navigate("/class");
  } catch (error) {
    console.error("Error checking class timestamp or updating scores:", error);
    throw error; // Optional: Re-throw if you want calling code to handle it
  }
}
