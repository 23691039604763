import { useEffect, useMemo } from "react";
import { ref, onChildAdded } from "firebase/database";
import { BoxingClass } from "../ui-components/helpers/CustomTypes";
import { useClassContext } from "../Contexts/ClassContext";

export const useFBOnChildAdded = (classId: string, setBoxingClass: React.Dispatch<React.SetStateAction<BoxingClass>>) => {
  const { db } = useClassContext();

  // Memoize classRef to avoid unnecessary re-creations
  const classRef = useMemo(() => {
    if (db !== null) {
      return ref(db, `classes/${classId}/scores`);
    } else {
      // console.log("db is null dont do anything");
    }
  }, [classId, db]);

  useEffect(() => {
    if (classRef !== undefined) {
      const unsubscribe = onChildAdded(classRef, (snapshot) => {
        const newUserData = snapshot.val();
        const userId = snapshot.key;

        if (typeof userId === "string") {
          setBoxingClass((prevState) => ({
            ...prevState,
            [classId]: {
              ...prevState[classId],
              scores: {
                ...prevState[classId]?.scores,
                [userId]: newUserData
              }
            }
          }));
          // console.log("onChildAdded setState");
        } else {
          console.error("Invalid user ID received from Firebase:", userId);
        }
      });

      return () => unsubscribe();
    }
  }, [db, classRef, classId, setBoxingClass]); // classRef is now stable, so the effect only re-runs if classId changes
};
