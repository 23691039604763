import { CSSProperties } from "react";

export const nameDisplayCardHeader: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  // paddingBottom: "0px",
  lineHeight: "0.8",
  padding: "0px",
  background: "#1E1E1E", // 1E1E1E
  display: "flex",
  alignItems: "center"
};
export const nameDisplayText: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600,
  // fontSize: "3.2rem",
  lineHeight: "0.8",
  padding: "10px 0px 10px 5px", // top, right , bottom, left
  color: "#FFFFFF" // FFFFFF white
};
export const MUICardContentStyle: CSSProperties = {
  padding: "0px 8px 10px 8px", // top right bottom left
  gap: "1%",
  display: "flex",
  flex: "1 1 100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#3B3B3B"
};
export const typographyStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flex: "1 1 100%"
};
export const roundPunches: CSSProperties = {
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600,
  gap: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "0.6",
  color: "#FFFFFF",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" //x offset y offset radius of blur rgba(r,g,b, % opacity)
  //innerShadow: blur 4, y 4 25% // pausing inner shadow
};
export const totalPunchesAndStars: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "space-evenly",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: "600",
  color: "#D9D9D9",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};
export const boxingGlove: CSSProperties = {
  filter: "brightness(70%)",
  // margin: "0px, 10px, 10px, 0px"
  marginTop: "10px"

  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" //trying to apply shadow, currently doesn't work as it treats the glove as a rectangle. Transparency isn't recognized
};

export const iconCountGap: CSSProperties = {
  display: "flex",
  gap: "6px",
  alignItems: "center"
};

export const userCardBoxingStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  backgroundColor: "transparent",
  height: "100%" // Ensures the user card takes the full height of its parent (the grid item)
};
