const addComma: (number: number) => string = (number) => {
  // console.log("trying to add comma. number: ", number);
  if (number === undefined) {
    return "0";
  }
  // regex inserts commas as thousand separators for any number length
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default addComma;
