import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import initFirebase from "../../Configs/initFirebase";
import { logEvent } from "firebase/analytics";
import { getWebAnalytics } from "../../Configs/initFirebase";
import { Grid, Container, Button } from "@mui/material";
import useSound from "use-sound";

import stripe from "../../Configs/initStripe";
import { useClassContext } from "../../Contexts/ClassContext";
import { useFBOnChildAdded } from "../../Hooks/useFBOnChildAdded";
import { useFBOnChildChanged } from "../../Hooks/useFBOnChildChanged";
import { useFBOnChildRemoved } from "../../Hooks/useFBOnChildRemoved";

import Powa_logo from "../../images/Powa_logo.png";
import alert_sound from "../../sounds/censor-beep-1sec.mp3";

import addComma from "../helpers/addComma";
import Sliders from "./Sliders";
import addCurrentConfigToFirestore from "./addConfigToFirestore";
import { Mode, User } from "../helpers/CustomTypes";
import { updateMetaWithClassParams } from "./updateMetaWithClassParams";
import { addMockUser } from "../03-BoxingPage/addMockUser";
import UserCardSetup from "../MUIComponents/02-UserCardSetup/UserCardSetup";

import {
  setupContainer,
  classIdLabel,
  sliderContainerStyles,
  gridContainerStyle,
  outerContainerStyle,
  userCardSetupGridStyle,
  powaLogo,
  logoSliderClassInfo,
  classIdDisplay,
  classIdContainer,
  powaLogoContainer,
  classIdPunchGoalStart,
  punchGoalContainer,
  punchGoalInput,
  userCountStyles,
  buttonStyle,
  punchGoalLabel,
  centeringGrid
} from "./Setup.styles";

export default function Setup(): JSX.Element {
  const {
    boxingClass,
    setBoxingClass,
    setTimer,
    startTimer,
    classId,
    numRounds,
    // setNumRounds,
    roundLength,
    // setRoundLength,
    breakLength,
    // setBreakLength,
    classPunchGoal,
    setClassPunchGoal,
    setCurrentRound,
    db,
    currentRound,
    setMode,
    setTotalPunchesAtRoundStart,
    continuousMode,
    setContinuousMode,
    soundsEnabled,
    setSoundsEnabled,
    buildEnv
  } = useClassContext();

  const navigate = useNavigate();

  useFBOnChildAdded(classId, setBoxingClass);
  useFBOnChildChanged(classId, setBoxingClass);
  useFBOnChildRemoved(classId, setBoxingClass);

  const scores = boxingClass[classId]?.scores;
  const userCount = scores ? Object.keys(scores).length : 0;
  // console.log("entry to Setup. continuousMode:", continuousMode);

  const [play] = useSound(alert_sound, {
    interrupt: true,
    soundEnabled: soundsEnabled
  });

  const handleStart = (): void => {
    logEvent(getWebAnalytics(), "workout_start", {
      classId: classId,
      userCount: userCount,
      continuousMode: continuousMode
    });

    const newMode: Mode = "active";
    setMode(newMode);

    if (continuousMode === "disabled") {
      play();
      setTimer(roundLength); // sets time left in round
      startTimer(newMode); // sets class in active state
      setCurrentRound(1);
      navigate("/class/boxing");
    } else if (continuousMode === "enabled") {
      startTimer(newMode); // sets class in active state, no timer
      navigate("/class/continuous");
    } else {
      console.log("Invalid continuous mode: ", continuousMode);
      navigate("/class/boxing");
    }

    // console.log("before adding to firestore. continuousMode: ", continuousMode);
    addCurrentConfigToFirestore({ numRounds, roundLength, breakLength, classPunchGoal, continuousMode });
    // updateMetaWithClassParams({db, classId, breakLength, roundLength, numRounds, currentRound, continuousMode});
    if (continuousMode === "disabled") {
      // we don't need to update meta params because meta already says continuous mode is active since it wrote to RTDB when the switch was flipped
      updateMetaWithClassParams({
        db: db,
        classId: classId,
        breakDuration: breakLength,
        roundDuration: roundLength,
        rounds: numRounds,
        currentRound: currentRound,
        continuousMode: continuousMode
      });
    }
  };

  const firestore = getFirestore(initFirebase());
  const auth = getAuth();
  const userEmail = auth.currentUser?.email;

  useEffect(() => {
    const fetchUserConfig = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log("No user is currently authenticated.");
        navigate("/login");
        return;
      }

      const docRef = doc(firestore, "gyms", user.email!);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        // console.log("No such document!");
        return;
      }

      const userData = docSnap.data();
      // console.log("User data:", userData);
      // Update your state with fetched data
      if (userData.workoutPreset) {
        if (userData.workoutPreset.continuousMode === "enabled" || userData.workoutPreset.continuousMode === "disabled") {
          // only set it if we actually have the value and its valid. Otherwise leave it so it doesn't get set to undefined and cause lots of issues
          setContinuousMode(userData?.workoutPreset?.continuousMode);
        }

        // use these if the user has no saved firestore config
        // setNumRounds(userData.workoutPreset.numRounds || 5);
        // setRoundLength(userData.workoutPreset.roundLength || 60);
        // setBreakLength(userData.workoutPreset.breakLength || 60);
        // setClassPunchGoal(userData.workoutPreset.classPunchGoal || 0);
      } else {
        // user has no data, we don't need to write the preset to their firestore. Start workout will write what they want to firestore
        // await addCurrentConfigToFirestore({ numRounds, roundLength, breakLength, classPunchGoal });
      }
    };

    fetchUserConfig();
    setTotalPunchesAtRoundStart({});
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (!scores) {
      // console.log("Scores are not available.");
      return;
    }
    if (continuousMode === "enabled") {
      // console.log("Continuous mode is active.");
      return;
    }

    const updatedScores = { ...scores };
    Object.keys(updatedScores).forEach((userId) => {
      updatedScores[userId] = {
        ...updatedScores[userId],
        Punches: { "0": 0 },
        Stars: { "0": 0 },
        RoundPunches: 0,
        Intensity: 0
      };
    });

    setBoxingClass((prevBoxingClass) => ({
      ...prevBoxingClass,
      [classId]: {
        ...prevBoxingClass[classId],
        scores: updatedScores
      }
    }));
  }, []); // Empty dependency array to run only once on mount

  const handleAddMockUser = (): void => {
    addMockUser(db!, classId);
  };

  const handlePurchasePremium = async (): Promise<void> => {
    const session = await stripe.checkout.sessions.create({
      line_items: [
        {
          price_data: {
            currency: "cad",
            product_data: {
              name: "POWA Subscription"
            },
            unit_amount: 5 * 100,
            recurring: {
              interval: "month"
            }
          },
          quantity: 1
        }
      ],
      mode: "subscription",
      success_url: "https://app.powaboxing.com/class",
      cancel_url: "https://app.powaboxing.com/class",
      customer_email: userEmail as string
    });

    if (!session.url) {
      return console.error("No url from stripe session");
    }

    // navigate(session.url); // /class/url
    // window.open(session.url, "_blank"); // new tab
    window.location.href = session.url; // same tab
  };

  return (
    <div style={setupContainer}>
      <div style={logoSliderClassInfo}>
        <div style={powaLogoContainer}>
          <a href="https://powaboxing.com/" target="_blank" rel="noopener noreferrer">
            <img style={powaLogo} src={Powa_logo} alt="Powa Logo" />
          </a>
          {/* <div style={volumeDiv}>
            <img style={volumeIcon} src={soundsEnabled ? volume_on : volume_off} alt="Sound Enable/Disable" />
          </div> */}
          {buildEnv === "development" && (
            <>
              <h1 style={{ color: "white", cursor: "pointer" }} onClick={handleAddMockUser}>
                Add Mock User
              </h1>
              <h1 style={{ color: "white", cursor: "pointer" }} onClick={handlePurchasePremium}>
                Purchase Premium
              </h1>
            </>
          )}
        </div>
        <div style={sliderContainerStyles}>
          <Sliders />

          {/* Button to add slider settings to firestore under current signed in user */}
          {/* <Button
            onClick={() => addCurrentConfigToFirestore({ numRounds, roundLength, breakLength, classPunchGoal })}
            style={Barlow600}
            variant="contained"
          >
            Add Workout Configuration to Firestore
          </Button> */}
        </div>
        <div style={classIdPunchGoalStart}>
          <div style={classIdContainer}>
            <h1 style={classIdLabel}>CLASS CODE:</h1>
            <p style={classIdDisplay}>{classId}</p>
          </div>
          <div style={punchGoalContainer}>
            <span style={punchGoalLabel}>CLASS PUNCH GOAL:</span>
            <input
              style={punchGoalInput}
              type="text"
              placeholder="ENTER CLASS PUNCH GOAL"
              value={addComma(classPunchGoal)}
              onChange={(e) => {
                // Remove commas before converting to a number. avoids setState errors as it is typed as a number and comma will be death
                const value = e.target.value.replace(/,/g, "");
                const numericValue = Number(value);
                if (!isNaN(numericValue)) {
                  setClassPunchGoal(numericValue);
                }
              }}
            />
          </div>
          <Button variant="contained" onClick={handleStart} style={buttonStyle}>
            {continuousMode === "enabled" ? "BEGIN SESSION" : "BEGIN WORKOUT"}
          </Button>
        </div>
      </div>

      <Container style={outerContainerStyle}>
        <div style={centeringGrid}>
          <Grid container spacing={2} style={gridContainerStyle}>
            {classId &&
              boxingClass[classId]?.scores &&
              Object.entries(boxingClass[classId].scores).map(([userId, user]: [string, User]) => (
                <Grid style={userCardSetupGridStyle} item key={userId} xs={12} sm={6} md={3} lg={3}>
                  <UserCardSetup user={user} userId={userId} />
                </Grid>
              ))}
          </Grid>
          {userCount < 16 ? <p style={userCountStyles}>{userCount}/16 ATHLETES</p> : null}
        </div>
      </Container>
    </div>
  );
}
