import { CSSProperties } from "react";

export const slidersContainerStyle: CSSProperties = {
  display: "flex",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between", // why does this property not apply?
  color: "#FFFFFF",
  position: "relative"
};

export const rowContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};

export const numberDisplay: CSSProperties = {
  color: "#FD4B03",
  fontSize: "36px",
  fontWeight: "bold",
  paddingBottom: "4px",
  paddingRight: "6px"
};

export const unitsDisplay: CSSProperties = {
  letterSpacing: "6px",
  fontSize: "12px"
};

export const volumeDiv: CSSProperties = {
  position: "absolute", // Positioning volume div absolutely
  left: "3%", // Align it to the left
  top: "4%", // Center it vertically
  transform: "translateY(-50%)", // Adjust vertical alignment
  width: "43px", // Set width to enclose the icon
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
};

export const volumeIcon: CSSProperties = {
  height: "40px"
};

export const sliderLabel: CSSProperties = {
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: "bolder",
  letterSpacing: "15px",
  fontSize: "18px"
};

export const smoothTransition: CSSProperties = {
  transition: "all 0.3s ease-in-out"
};

export const greyFilterStyle: CSSProperties = {
  position: "relative",
  filter: "grayscale(100%)",
  opacity: 0.5
};
