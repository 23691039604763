import initFirebase from "../../Configs/initFirebase";

import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { ContinuousString, workoutPreset } from "../helpers/CustomTypes";
import fetchUserRegion from "../00-Login/fetchUserRegion";

interface UserData {
  lastLogin: string;
  email: string | null;
  region?: string; // region optional
  workoutPreset: workoutPreset;
}

const addCurrentConfigToFirestore = async ({ numRounds, roundLength, breakLength, classPunchGoal, continuousMode }: workoutPreset) => {
  const firestore = getFirestore(initFirebase());
  const auth = getAuth();

  const user = auth.currentUser;
  if (!user) {
    console.error("No user signed in");
    return; // Early return if no user is signed in
  }

  try {
    const region = await fetchUserRegion();
    const currentDate = new Date();
    const userData: UserData = {
      lastLogin: currentDate.toJSON(),
      email: user.email,
      workoutPreset: {
        continuousMode,
        numRounds,
        roundLength,
        breakLength,
        classPunchGoal
      }
    };

    if (region === undefined) {
      userData.region = "na";
    }

    const stringUserEmail: string = user.email as string;

    await setDoc(doc(firestore, "gyms", stringUserEmail), userData, { merge: true });
  } catch (error) {
    console.error("Error writing data to Firestore: ", error);
    alert("Failed to add data to Firestore.");
  }
};

export default addCurrentConfigToFirestore;
