import { CSSProperties } from "react";

export const userCardSetupStyles: CSSProperties = {
  background: "#3B3B3B",
  color: "#FFFFFF",
  margin: "0px",
  padding: "0px",
  width: "340px",
  height: "70px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative"
};

export const NameStyles: CSSProperties = {
  fontSize: "2.2rem",
  fontFamily: "'Barlow Semi Condensed', sans-serif",
  fontWeight: 600
};

export const inlineContainer: CSSProperties = {
  display: "inline-flex",
  alignItems: "center" // Ensures vertical alignment of text and emoji
};

export const emojiSize: CSSProperties = {
  fontSize: "1.6rem" // Matches the font size of the text
};

export const xButtonContainer: CSSProperties = {
  position: "absolute",
  top: -5,
  right: -5,
  padding: "10px"
};

export const xButton: CSSProperties = {
  height: "20px",
  color: "#FFFFFF",
  filter: "brightness(70%)",
  cursor: "pointer"
};
