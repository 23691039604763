import { useClassContext } from "../../Contexts/ClassContext";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { Container, Grid } from "@mui/material";
import FinishUserGrid from "./FinishUserGrid";
import BottomBar from "../03-BoxingPage/BottomBar";
import { containerStyles, gridContainerStyles } from "./Finish.styles";
import clearClassScores from "../00-Login/clearClassScores";

export default function Finish() {
  const { classId, db } = useClassContext();
  const auth = getAuth();
  const navigate = useNavigate();
  // data is removed on RTDB when transitioning to finish, do not add rtdb hooks

  const PopulatedFinishUserGrid = FinishUserGrid();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.log("No user is currently authenticated, going login.");
      navigate("/login");
    }

    if (classId) {
      clearClassScores(db!, classId);
    }
  }, []);

  return (
    <>
      <Container style={containerStyles}>
        <Grid container spacing={2} style={gridContainerStyles}>
          {PopulatedFinishUserGrid}
        </Grid>
      </Container>
      <BottomBar />
    </>
  );
}
