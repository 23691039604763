import { UserCardStyles } from "../helpers/CustomTypes";

/*
REMINDER:
easiest way to format this is to pause the workout and add people on firebase. This way you can test all the formats. User numbers to test: 3, 5, 8, 10, 13
*/

const calcGridStylesAndHeightFinish = (userCount: number) => {
  let gridSizes = { xs: 12, sm: 6, md: 6, lg: 6 }; // Default grid sizes
  let height = "42vh"; // Default height
  let internalStyle: UserCardStyles = {
    // values for 1 - 4 users
    formatting: { name: "5rem", roundPunches: "220px", totalPunchesandStars: "6rem", gloveSize: "160px", emojiSize: "60px" }
  };

  if (userCount >= 1 && userCount <= 4) {
    // values for 1 - 4 users
    // gridSizes = { xs: 12, sm: 6, md: 6, lg: 6 }; // 2 horz, no need to change
    // height = "42vh"; // Two users horizontally and two vertically, half height
    // internalStyle.formatting = { name: "5rem", roundPunches: "220px", totalPunchesandStars: "6rem", gloveSize: "160px", emojiSize: "60px" };
  } else if (userCount >= 5 && userCount <= 6) {
    gridSizes = { xs: 12, sm: 4, md: 4, lg: 4 }; // 3 horz
    height = "42vh"; // Three users horizontally and two vertically, half height
    internalStyle.formatting = { name: "5rem", roundPunches: "220px", totalPunchesandStars: "5rem", gloveSize: "150px", emojiSize: "60px" };
  } else if (userCount >= 7 && userCount <= 9) {
    gridSizes = { xs: 12, sm: 4, md: 4, lg: 4 }; // 3 horz
    height = "28.5vh"; // Three users horizontally and three vertically
    internalStyle.formatting = { name: "5rem", roundPunches: "115px", totalPunchesandStars: "4rem", gloveSize: "85px", emojiSize: "50px" };
  } else if (userCount >= 10 && userCount <= 12) {
    gridSizes = { xs: 12, sm: 4, md: 3, lg: 3 }; // 4 horz
    height = "28.5vh"; // Four users horizontally and three vertically
    internalStyle.formatting = {
      name: "3.2rem",
      roundPunches: "140px",
      totalPunchesandStars: "3rem",
      gloveSize: "100px",
      emojiSize: "39px"
    };
  } else if (userCount >= 13 && userCount <= 16) {
    gridSizes = { xs: 12, sm: 3, md: 3, lg: 3 }; // 4 horz
    height = "21.7vh"; // Four users horizontally and four vertically, smaller height
    internalStyle.formatting = {
      name: "2.5rem",
      roundPunches: "90px",
      totalPunchesandStars: "3rem",
      gloveSize: "65px",
      emojiSize: "30px"
    };
  } else if (userCount > 16) {
    gridSizes = { xs: 12, sm: 3, md: 3, lg: 3 }; // 4 horz
    height = "21.7vh"; // Four users horizontally and four vertically, smaller height
    internalStyle.formatting = {
      name: "2.5rem",
      roundPunches: "90px",
      totalPunchesandStars: "3rem",
      gloveSize: "65px",
      emojiSize: "30px"
    };
  } else {
    //error handling for < 16 users or invalid user count
  }
  return { gridSizes, height, internalStyle };
};

export default calcGridStylesAndHeightFinish;
