import { CSSProperties } from "react";

export const modalStyle: CSSProperties = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxWidth: 800,
  height: 550,
  maxHeight: 550,
  background: "#3B3B3B",
  border: "12px solid #262626",
  borderRadius: "5px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  outline: "none"
  // p: 4
};

export const modalTitle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600,
  fontSize: "75px",
  letterSpacing: "0.46px",
  background: "#1E1E1E",
  color: "#FFFFFF",
  padding: "35px 50px 45px 50px",
  position: "relative",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
};

export const modalContent: CSSProperties = {
  color: "#3B3B3B",
  height: "400px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "40px 0px 60px 0px" // top, right , bottom, left
};

export const cancelButtonStyle: CSSProperties = {
  color: "#FFFFFF",
  background: "#f2662D"
};

export const confirmButtonStyle: CSSProperties = {
  color: "#FFFFFF",
  background: "rgba(241, 67, 67, 0.85)" // F14343
};

export const confirmationMessage: CSSProperties = {
  color: "#FFFFFF",
  fontSize: "35px",
  fontFamily: "'Barlow Condensed', sans-serif",
  fontWeight: 600
};
