import { Container, Grid } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useClassContext } from "../../Contexts/ClassContext";
import { useFBOnChildAdded } from "../../Hooks/useFBOnChildAdded";
import { useFBOnChildChanged } from "../../Hooks/useFBOnChildChanged";
import { useFBOnChildRemoved } from "../../Hooks/useFBOnChildRemoved";

import BoxingUserGrid from "./BoxingUserGrid";
import BottomBar from "./BottomBar";

import { containerStyles, gridContainerStyles } from "./Boxing.styles";

export default function Boxing(): JSX.Element {
  const { classId, setBoxingClass } = useClassContext();
  const auth = getAuth();
  const navigate = useNavigate();

  useFBOnChildAdded(classId, setBoxingClass);
  useFBOnChildChanged(classId, setBoxingClass);
  useFBOnChildRemoved(classId, setBoxingClass);

  const PopulatedBoxingUserGrid = BoxingUserGrid();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.log("No user is currently authenticated, going login.");
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Container style={containerStyles}>
        <Grid container spacing={2} style={gridContainerStyles}>
          {PopulatedBoxingUserGrid}
        </Grid>
      </Container>
      <BottomBar />
    </>
  );
}
