import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ref, DatabaseReference } from "firebase/database";
import Button from "@mui/material/Button";

import { useClassContext } from "../../Contexts/ClassContext";
import fetchClassIds from "./fetchClassIds";
import { generateNewClass } from "./generateNewClass";

import {
  powaLogo,
  powaLogoContainer,
  startPageStyles,
  buttonStyle,
  classIdInputLabel,
  classIdInput,
  inputAndButtonContainer
} from "./StartPage.styles";
import { Barlow800 } from "../global.styles/shared.styles";
import Powa_logo from "../../images/Powa_logo.png";

function StartPage() {
  const { classId, setClassId, firebaseClassIDs, setFirebaseClassIDs, db } = useClassContext();
  const [inputClassId, setInputClassId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const classesRef: DatabaseReference = ref(db!, `classes/`);

    const getClassIDs = async () => {
      const fetchedClassIDs = await fetchClassIds(classesRef);
      setFirebaseClassIDs(fetchedClassIDs);
    };

    getClassIDs();
  }, [setFirebaseClassIDs]);

  const handleStartClass = async () => {
    if (inputClassId.length > 3) {
      setClassId(inputClassId); // now both are streamlined to classId
    }

    navigate(`/class/`);
  };

  return (
    <div style={startPageStyles}>
      <div style={powaLogoContainer}>
        <a href="https://powaboxing.com/" target="_blank" rel="noopener noreferrer">
          <img style={powaLogo} src={Powa_logo} alt="Powa Logo" />
        </a>
      </div>
      <div style={inputAndButtonContainer}>
        <h1 style={classIdInputLabel}>WELCOME TO CLASS: {classId || "NONE"}</h1>
        <input
          style={classIdInput}
          type="text"
          placeholder="ENTER CLASS ID"
          value={inputClassId}
          onChange={(e) => setInputClassId(e.target.value.toUpperCase())}
        />
        <Button
          onClick={() => generateNewClass(classId, setClassId, firebaseClassIDs, db)}
          style={{ ...Barlow800, ...buttonStyle }}
          variant="contained"
        >
          Generate Class ID
        </Button>
        <Button onClick={handleStartClass} style={{ ...Barlow800, ...buttonStyle }} variant="contained">
          Start
        </Button>
      </div>
    </div>
  );
}

export default StartPage;
