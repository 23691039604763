import { ref, get, Database } from "firebase/database";
import { NavigateFunction } from "react-router-dom";

import assessClearScores from "./assessClearScores";
import { incrementClassId } from "./incrementClassId";
import { checkClassIdTaken } from "./checkClassIdTaken";
import { initializeNewClass } from "./initializeNewClass";
import { ContinuousString, Mode } from "../helpers/CustomTypes";

const handleCurrentUserRole = async (
  db: Database,
  classId: string,
  currentUserEmail: string,
  setClassId: (id: string) => void,
  firebaseClassIDs: string[],
  setContinuousMode: (mode: ContinuousString) => void,
  setOpenModal: (val: boolean) => void,
  navigate: NavigateFunction,
  setIsTimerRunning: (val: boolean) => void,
  setPrevMode: (mode: Mode) => void,
  setMode: (mode: Mode) => void
): Promise<void> => {
  const metaRef = ref(db, `classes/${classId}/meta`);
  // console.log("checking for if classId is set handleUserRole29: ", classId);

  try {
    const snapshot = await get(metaRef);
    // console.log("checking if statement validity", snapshot, "current user email: ", currentUserEmail);
    if (snapshot.exists() && snapshot.val().creator === currentUserEmail) {
      // Current user is the creator
      // console.log("snapshot in handleUserRole: ", snapshot);
      await assessClearScores(db, classId, setContinuousMode, setOpenModal, navigate, setIsTimerRunning, setPrevMode, setMode);
      setClassId(classId);
    } else {
      // Current user is not the creator or class does not exist
      // console.log("checking for if classId is set handleUserRole40: ", classId);
      let incrementedId = incrementClassId(classId);
      while (checkClassIdTaken(incrementedId, firebaseClassIDs)) {
        incrementedId = incrementClassId(incrementedId);
      }
      // console.log("Incremented id: ", incrementedId);
      await initializeNewClass(db, incrementedId, currentUserEmail);
      setClassId(incrementedId);
      navigate("/class");
    }
  } catch (error) {
    console.error("Error processing class creator role:", error);
    // Handle any errors that occurred during the process
  }
};

export default handleCurrentUserRole;
