import { initializeApp, getApps, getApp, FirebaseApp } from "firebase/app";
import { Database, getDatabase } from "firebase/database";
import fetchUserRegion from "./fetchUserRegion";
import initFirebase from "../../Configs/initFirebase";
import { BuildEnvString } from "../helpers/CustomTypes";
import { useEffect } from "react";

interface RTDBUrls {
  [key: string]: string;
}

const RTDB_DEV_URLS: RTDBUrls = {
  eu: "https://powa-boxing-dev-belgium.europe-west1.firebasedatabase.app/",
  na: "https://powa-boxing-dev-default-rtdb.firebaseio.com/",
  as: "https://powa-boxing-dev-asia.asia-southeast1.firebasedatabase.app/"
};

const RTDB_PROD_URLS: RTDBUrls = {
  eu: "https://powa-boxing-eu.europe-west1.firebasedatabase.app/",
  na: "https://powa-boxing-default-rtdb.firebaseio.com/",
  as: "https://powa-boxing-asia.asia-southeast1.firebasedatabase.app/"
};

// This function initializes the RTDB based on the user's region
export default async function connectRTDBForRegion(setDb: (db: Database) => void, buildEnv: BuildEnvString): Promise<Database> {
  const region: string | undefined = await fetchUserRegion();

  let databaseURL: string = "";
  // console.log("buildEnv: ", buildEnv, "region: ", region, "dbURL: ", databaseURL);
  if (buildEnv === "development") {
    databaseURL = RTDB_DEV_URLS[region as keyof typeof RTDB_DEV_URLS] || RTDB_DEV_URLS.na;
  } else if (buildEnv === "production") {
    // console.log("in prod");
    databaseURL = RTDB_PROD_URLS[region as keyof typeof RTDB_PROD_URLS] || RTDB_PROD_URLS.na;
    // console.log("region: ", region, "databaseURL: ", databaseURL);
  }
  // console.log("buildEnv: ", buildEnv, "region: ", region, "dbURL: ", databaseURL);

  let app: FirebaseApp;
  if (!getApps().length) {
    // Only initialize the app if it hasn't been initialized before
    const config = initFirebase().options; // Get initial configuration
    app = initializeApp({ ...config, databaseURL }); // Initialize with the desired database URL
  } else {
    app = getApp(); // Use the existing initialized app
  }
  // console.log("app: ", app);

  // Specify the database URL directly when getting the database instance
  const foundDatabase = getDatabase(app, databaseURL);
  setDb(foundDatabase);
  return foundDatabase;
}
