// this compat stands for compatibility so you can use the newest version
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/database";

import { Dispatch, SetStateAction } from "react";
import { BuildEnvString } from "../ui-components/helpers/CustomTypes";

interface Config {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const configDev: Config = {
  apiKey: process.env.REACT_APP_API_KEY!, // ! means non-null assertion so it will let you know if its missing
  authDomain: process.env.REACT_APP_AUTH_DOMAIN!,
  databaseURL: process.env.REACT_APP_DATABASE_URL!,
  projectId: process.env.REACT_APP_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_APP_ID!,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID!
};

const configProd: Config = {
  apiKey: process.env.REACT_APP_PROD_API_KEY!,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN!,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL!,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_PROD_APP_ID!,
  measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID!
};

export default function initFirebase(setBuildEnv?: Dispatch<SetStateAction<BuildEnvString>>): firebase.app.App {
  let firebaseConfig: Config = configDev; // initialize with the default one

  // only set if setBuildEnv was passed in as that happens after the login component has rendered
  // we need to conditionally define things before app return, we can't happy path
  if (typeof setBuildEnv === "function") {
    // ################ swap dev environments ################
    // leave one of these lines commented out depending on which env you want to use:
    // const chooseOneEnvironment: BuildEnvString | String = "development";
    const chooseOneEnvironment: BuildEnvString | String = "production";

    if (chooseOneEnvironment === "development") {
      firebaseConfig = configDev;
      setBuildEnv("development");
    } else if (chooseOneEnvironment === "production") {
      firebaseConfig = configProd;
      setBuildEnv("production");
    }
  }
  if (!firebase.apps.length) {
    // console.log("firebaseConfig: ", firebaseConfig);
    const appWeb: firebase.app.App = firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  return firebase.app();
}

export function getWebAnalytics(): firebase.analytics.Analytics {
  const webAnalytics: firebase.analytics.Analytics = firebase.analytics(initFirebase());
  return webAnalytics;
}
