import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { useClassContext } from "../../Contexts/ClassContext";
import { loginPageStyles, powaLogoContainer, powaLogo, loginHeader, buttonStyle, contactUs } from "./Login.styles";
import { Barlow800 } from "../global.styles/shared.styles";
import Powa_logo from "../../images/Powa_logo.png";
import googleSignIn from "./googleSignIn";
import initFirebase from "../../Configs/initFirebase";
import { useEffect } from "react";

export default function Login() {
  const {
    setClassId,
    setFirebaseClassIDs,
    setDb,
    setContinuousMode,
    setOpenModal,
    setIsTimerRunning,
    setPrevMode,
    setMode,
    buildEnv,
    setBuildEnv
  } = useClassContext();
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("calling useEffect setBuildEnv should be there: ", buildEnv, "typeof setBuildEnv: ", typeof setBuildEnv);
    initFirebase(setBuildEnv);
  }, []);

  const handleGoogleSignIn = () => {
    googleSignIn(
      setClassId,
      setFirebaseClassIDs,
      setDb,
      setContinuousMode,
      setOpenModal,
      navigate,
      setIsTimerRunning,
      setPrevMode,
      setMode,
      buildEnv
    );
  };

  return (
    <div style={loginPageStyles}>
      <div style={powaLogoContainer}>
        <img style={powaLogo} src={Powa_logo} alt="Powa Logo" />
      </div>
      {/* <h1 style={loginHeader}>Login</h1> */}
      <Button onClick={handleGoogleSignIn} style={{ ...Barlow800, ...buttonStyle }} variant="contained">
        Sign in
      </Button>
      {/* Optional Guest access button */}
      {/* <Button onClick={handleGuestAccess} style={{ ...Barlow800, ...buttonStyle }} variant="contained">
        Continue as Guest
      </Button> */}
      <a
        href="https://powaboxing.com/pages/contact"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button variant="contained" style={contactUs}>
          CONTACT US
        </Button>
      </a>
    </div>
  );
}
