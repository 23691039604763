export default function intensityToRGBA(intensity: number = 50): string {
  // Ensure intensity is within bounds
  intensity = Math.min(100, Math.max(1, intensity));

  // Color start and end values
  const startColor = { r: 40, g: 187, b: 221 };
  const endColor = { r: 253, g: 75, b: 3 };

  // Calculate the color based on intensity
  const color = {
    r: startColor.r + ((endColor.r - startColor.r) * intensity) / 100,
    g: startColor.g + ((endColor.g - startColor.g) * intensity) / 100,
    b: startColor.b + ((endColor.b - startColor.b) * intensity) / 100
  };

  return `rgba(${Math.round(color.r)}, ${Math.round(color.g)}, ${Math.round(color.b)}, 1)`;
}
