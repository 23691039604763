export const incrementClassId = (classId: string): string => {
  if (classId.length !== 4 || !/^[A-Z]+$/.test(classId)) {
    console.log("classId unable to be incremented: ", classId);
    throw new Error("Class ID must be a 4-letter uppercase string");
  }

  let incrementedId = "";
  let carry = 1; // Start with a carry to increment the last letter

  for (let i = 3; i >= 0; i--) {
    if (carry === 0) {
      incrementedId = classId[i] + incrementedId;
      continue;
    }

    let charCode = classId.charCodeAt(i) + carry;

    if (charCode > "Z".charCodeAt(0)) {
      charCode = "A".charCodeAt(0);
      carry = 1;
    } else {
      carry = 0;
    }

    incrementedId = String.fromCharCode(charCode) + incrementedId;
  }
  // console.log("Old class ID:", classId, "Incremented class ID: ", incrementedId);
  return incrementedId;
};

// Example usage
// console.log("BHTZ => ", incrementClassId('BHTZ')); // Should output 'BHUA' // does correctly 20231226
// console.log("ZZZZ => ", incrementClassId('ZZZZ')); // Should output 'AAAA' // does correctly 20231226
// console.log("AAAA => ", incrementClassId('AAAA')); // Should output 'AAAB' // does correctly 20231226
// console.log("AZZZ => ", incrementClassId('AZZZ')); // Should output 'BAAA' // does correctly 20231226
