import { Dispatch, SetStateAction } from "react";
import { ref, set, DatabaseReference, Database } from "firebase/database";

import fetchClassIds from "./fetchClassIds";
import { incrementClassId } from "../00-Login/incrementClassId";
import { checkClassIdTaken } from "../00-Login/checkClassIdTaken";

export const generateNewClass = async (
  classId: string,
  setClassId: Dispatch<SetStateAction<string>>,
  firebaseClassIDs: string[],
  db: Database | null
) => {
  if (firebaseClassIDs.includes(classId)) {
    alert("There already is a class ready for you");
  }
  const classesRef: DatabaseReference = ref(db!, `classes/`);
  const fetchedClassIDs = await fetchClassIds(classesRef);

  const latestClassID = fetchedClassIDs
    .filter((id) => /^[A-Z]+$/.test(id))
    .sort()
    .pop();

  if (latestClassID) {
    const newClassId = incrementClassId(latestClassID);
    if (!checkClassIdTaken(newClassId, fetchedClassIDs)) {
      const newClassRef = ref(db!, `classes/${newClassId}`);
      await set(newClassRef, {
        scores: {
          // ajg4560897asf3: { Name: "Sarah Lam", Punches: 1301, Stars: 3.3 },
          // gf997dak2kjdfu: { Name: "Janine Serene", Punches: 1204, Stars: 1.1 }
        },
        meta: {
          mode: "break"
        }
      });
      setClassId(newClassId);
    } else {
      alert("Class ID already taken. Please try generating again.");
    }
  }
};
