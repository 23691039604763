import Pause_Button from "../../images/Pause_button.png";
import Outer_Circle from "../../images/Outer_circle.png";
import Play_Icon from "../../images/Play_icon.png";
import Orange_x_icon from "../../images/Orange_x_icon.png";

import { pauseSubtext, pauseButtonStyle, playButtonCircle, playIcon, OrangeX } from "./Boxing.styles";

export const getBottomBarContent = (pathname: string, isTimerRunning: boolean, mode: string): JSX.Element | null => {
  switch (true) {
    case pathname.endsWith("/boxing"):
      return (
        <>
          {isTimerRunning ? (
            <img style={pauseButtonStyle} src={Pause_Button} alt="Pause Button" />
          ) : (
            <div>
              <img style={playButtonCircle} src={Outer_Circle} alt="Play Button" />
              <img style={playIcon} src={Play_Icon} alt="Play Button" />
            </div>
          )}
          <span style={pauseSubtext}>{isTimerRunning ? "PAUSE" : "RESUME"}</span>
        </>
      );
    case pathname.endsWith("/finish"):
      return (
        <>
          <div>
            <img style={playButtonCircle} src={Outer_Circle} alt="Play Button" />
            <img style={OrangeX} src={Orange_x_icon} alt="Exit Button" />
          </div>
          <span style={pauseSubtext}>EXIT</span>
        </>
      );
    case pathname.endsWith("/continuous"):
      return (
        <>
          {mode === "active" ? (
            <img style={pauseButtonStyle} src={Pause_Button} alt="Pause Button" />
          ) : (
            <div>
              <img style={playButtonCircle} src={Outer_Circle} alt="Play Button" />
              <img style={playIcon} src={Play_Icon} alt="Play Button" />
            </div>
          )}
          <span style={pauseSubtext}>{mode === "active" ? "PAUSE" : "RESUME"}</span>
        </>
      );
    default:
      return null;
  }
};
