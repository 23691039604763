import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";

import { BoxingClass, Mode } from "../helpers/CustomTypes";
import logWorkoutCompleted from "./logWorkoutCompleted";

interface workoutCompletedParams {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  stopTimer: (overrideMode: Mode) => void;
  setMode: Dispatch<SetStateAction<Mode>>;
  setTimer: Dispatch<SetStateAction<number>>;
  setIsBreakTime: Dispatch<SetStateAction<boolean>>;
  setCurrentRound: (newRound: number) => void;
  setTotalPunchesAtRoundStart: Dispatch<SetStateAction<BoxingClass>>;
  navigate: NavigateFunction;
  classId: string;
}

const workoutCompleted = async ({
  setOpenModal,
  stopTimer,
  setMode,
  setTimer,
  setIsBreakTime,
  setCurrentRound,
  setTotalPunchesAtRoundStart,
  navigate,
  classId
}: workoutCompletedParams): Promise<void> => {
  setOpenModal(false);
  stopTimer("completed");
  setMode("break");
  setTimer(0);
  setIsBreakTime(false);
  setCurrentRound(1);
  setTotalPunchesAtRoundStart({}); // reset punches in local state
  await navigate("/class/finish");
  logWorkoutCompleted(classId);
};

export default workoutCompleted;
