import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLocation } from "react-router-dom";

import { useClassContext } from "../../../Contexts/ClassContext";
import Modal_x_button from "../../../images/Modal_x_button.png";
import addComma from "../../helpers/addComma";
import { GetPauseModalButtons } from "./GetPauseModalButtons";

import volume_on from "../../../images/volume_on.png";
import volume_off from "../../../images/volume_off.png";

import {
  joinNow,
  joinNowClassId,
  modalContent,
  modalStyle,
  modalTitle,
  punchGoalInput,
  punchGoalLabel,
  roundedCorners,
  punchGoalContainer,
  xButton,
  xButtonContainer,
  volumeDiv,
  volumeIcon
} from "./PauseModal.styles";

export default function PauseModal() {
  const {
    openModal,
    setOpenModal,
    classPunchGoal,
    setClassPunchGoal,
    isTimerRunning,
    startTimer,
    classId,
    prevMode,
    soundsEnabled,
    setSoundsEnabled
  } = useClassContext();
  const location = useLocation();

  // const handleOpen = () => setOpen(true);
  // boolean
  const boxingURL = location.pathname.endsWith("/boxing");
  const finishURL = location.pathname.endsWith("/finish");
  const continuousURL = location.pathname.endsWith("/continuous");

  // this function handles what happens when you click outside the modal box. for both boxing modes (boxing & continuous), we want to resume the timer on click outside the box.
  const handleClose = () => {
    setOpenModal(false);
    if (boxingURL || continuousURL) {
      startTimer(prevMode);
    }
  };

  const handleSoundsEnable = (): void => {
    soundsEnabled ? setSoundsEnabled(false) : setSoundsEnabled(true);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 1 }}
      >
        <Box sx={modalStyle}>
          <div style={roundedCorners}>
            <div style={modalTitle}>
              <span>{finishURL ? "FINISHED" : isTimerRunning ? "UNPAUSED" : "PAUSED"}</span>
              <div style={joinNowClassId}>
                <span style={joinNow}>{boxingURL ? "JOIN NOW:" : "CLASS:"}</span>
                <span>{classId}</span>
              </div>
              <div style={xButtonContainer}>
                <img onClick={handleClose} style={xButton} src={Modal_x_button} alt="" />
              </div>
            </div>
            <div style={modalContent}>
              <div style={punchGoalContainer}>
                {(boxingURL || continuousURL) && (
                  <>
                    <span style={punchGoalLabel}>CLASS PUNCH GOAL:</span>
                    <input
                      style={punchGoalInput}
                      type="text"
                      placeholder="ENTER CLASS PUNCH GOAL"
                      value={addComma(classPunchGoal)}
                      onChange={(e) => {
                        // Remove commas before converting to a number. avoids setState errors as it is typed as a number and comma will be death
                        const value = e.target.value.replace(/,/g, "");
                        const numericValue = Number(value);
                        if (!isNaN(numericValue)) {
                          setClassPunchGoal(numericValue);
                        }
                      }}
                    />
                  </>
                )}
              </div>
              {GetPauseModalButtons(location.pathname)}
              <div style={volumeDiv} onClick={handleSoundsEnable}>
                <img style={volumeIcon} src={soundsEnabled ? volume_on : volume_off} alt="Sound Enable/Disable" />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
